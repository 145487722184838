<template>
  <button
    type="button"
    class="btn btn-sm btn-secondary"
    :disabled="isDisabled"
    @click="
      () => {
        state.isAddItemModal = true;
      }
    "
  >
    <i class="fas fa-plus"></i>
    Add item
  </button>

  <OrderAddItemModal
    v-if="state.isAddItemModal === true"
    :id="id"
    @hide="
      () => {
        state.isAddItemModal = false;
      }
    "
    @item-added="() => itemsAdded()"
  />
</template>

<script lang="ts">
import { defineComponent, PropType, reactive } from "vue";
import OrderAddItemModal from "@/modules/orders/widgets/OrderItems/OrderAddItemModal.vue";

interface ComponentData {
  isAddItemModal: boolean;
}

export default defineComponent({
  name: "OrderAddItemsButton",
  components: { OrderAddItemModal },
  props: {
    id: {
      type: Number,
      required: true,
    },
    isDisabled: {
      required: true,
      type: Boolean as PropType<boolean>,
    },
  },

  emits: ["updated"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      isAddItemModal: false,
    });

    function itemsAdded() {
      state.isAddItemModal = false;
      emit("updated");
    }
    return {
      state,
      props,
      itemsAdded,
    };
  },
});
</script>

<style scoped></style>
