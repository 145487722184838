
import { defineComponent, onMounted, PropType, reactive } from "vue";
import { ErrorMessage, Form } from "vee-validate";
import { OrderStatusType } from "@/modules/orders/interfaces/OrderStatusHistoryResponse.interface";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { ElMessageBox } from "element-plus";
import Multiselect from "@vueform/multiselect";
import { MultiselectOptions } from "@/components/multiselect-options/MultiselectOptionsInterface";
import Notify from "@/modules/common/helpers/Notify";

interface ComponentData {
  loading: boolean;
  statusesOptions: MultiselectOptions[] | null;
  selectedStatus: number | null;
}

export default defineComponent({
  name: "OrderStatusHistoryForm",
  components: {
    Multiselect,
    Form,
    ErrorMessage,
  },
  props: {
    types: {
      type: Object as PropType<OrderStatusType>,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },

  emits: ["updated"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      loading: false,
      statusesOptions: null,
      selectedStatus: null,
    });

    const onSubmit = (): void => {
      ElMessageBox.confirm(
        "This will update order's status. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          sendRequest();
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const sendRequest = (): boolean => {
      if (state.selectedStatus == null) {
        Notify.apiError({
          name: "notSelectedStatusId",
          message: "Please select status ID",
        });

        return false;
      }

      state.loading = true;
      ApiService.post(`order/${props.id}/status-history`, {
        status_id: state.selectedStatus,
      })
        .then(() => {
          emit("updated");
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            text: error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        })
        .finally(() => {
          state.loading = false;
        });

      return true;
    };

    const setOptionsValues = (items) => {
      // Function prepares options values for Multiselect widget
      let options: MultiselectOptions[] = [];

      options = items.map(function (item) {
        if (item.title == null) {
          item.title = item.name;
        }
        return {
          value: item.id,
          name: item.title,
          label: item.title,
        };
      });

      return options;
    };

    onMounted(() => {
      state.statusesOptions = setOptionsValues(props.types);
    });

    return {
      state,
      props,
      onSubmit,
    };
  },
});
