
import { defineComponent, PropType, reactive, ref } from "vue";
import OrderRefundsButtonsAlert from "@/modules/orders/widgets/OrderRefunds/OrderRefundsButtonsAlert.vue";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import { OrderBalance } from "@/modules/orders/interfaces/OrderBalance.interface";
import { OrderPaymentsResponse } from "@/modules/orders/interfaces/OrderPaymentsResponse.interface";
import { OrderTrackingHistory } from "@/modules/orders/interfaces/OrderTrackingHistory.interface";
import OrderRefundsWidget from "@/modules/orders/widgets/OrderRefunds/OrderRefundsWidget.vue";
import OrderInvoicesWidget from "@/modules/orders/widgets/OrderInvoices/OrderInvoicesWidget.vue";
import OrderMessagesWidget from "@/modules/orders/widgets/OrderMessages/OrderMessagesWidget.vue";
import OrderInformationWidget from "@/modules/orders/widgets/OrderInformation/OrderInformationWidget.vue";
import OrderItemsWidget from "@/modules/orders/widgets/OrderItems/OrderItemsWidget.vue";
import OrderStatusHistoryWidget from "@/modules/orders/widgets/OrderStatusHistory/OrderStatusHistoryWidget.vue";
import PreviousOrdersWidget from "@/modules/common/widgets/PreviousOrders/PreviousOrdersWidget.vue";
import OrderTrackingWidget from "@/modules/orders/widgets/OrderTracking/OrderTrackingWidget.vue";
import OrderPaymentsWidget from "@/modules/orders/widgets/OrderPayments/OrderPaymentsWidget.vue";
import OrderMarksWidget from "@/modules/orders/widgets/OrderMarks/OrderMarksWidget.vue";
import OrderTrackingErrorsWidget from "@/modules/orders/widgets/OrderTracking/OrderTrackingErrorsWidget.vue";

interface ComponentData {
  orderBalance?: OrderBalance | null;
  orderPayments?: OrderPaymentsResponse | null;
}

export default defineComponent({
  name: "OrderBasicLayout",
  components: {
    OrderTrackingErrorsWidget,
    OrderMarksWidget,
    OrderInvoicesWidget,
    OrderPaymentsWidget,
    OrderRefundsWidget,
    OrderTrackingWidget,
    PreviousOrdersWidget,
    OrderStatusHistoryWidget,
    OrderItemsWidget,
    OrderMessagesWidget,
    OrderInformationWidget,
    OrderRefundsButtonsAlert,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    orderTracking: {
      type: Object as PropType<OrderTrackingHistory[]>,
      required: true,
    },
  },
  emits: ["updated"],

  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      orderBalance: null,
      orderPayments: null,
    });

    const refunds = ref<typeof OrderRefundsWidget | null>(null);
    const invoices = ref<typeof OrderInvoicesWidget | null>(null);
    const messages = ref<typeof OrderMessagesWidget | null>(null);
    const orderInformationWidget = ref<typeof OrderInformationWidget | null>(
      null
    );

    const getOrderBalance = () => {
      ApiService.get(`order/${props.id}/balance`)
        .then(({ data }) => {
          state.orderBalance = data;
        })
        .catch((error) => {
          Notify.apiError(error);
        });
    };

    const getOrderPayments = () => {
      ApiService.get(`order/${props.id}/payments`)
        .then(({ data }) => {
          state.orderPayments = data;
        })
        .catch((error) => {
          Notify.apiError(error);
        });
    };

    const refreshOrder = () => {
      emit("updated");
      getOrderBalance();
      getOrderPayments();

      refunds.value?.loadData();
      messages.value?.loadData();
      invoices.value?.loadData();
      orderInformationWidget.value?.loadData();
    };

    refreshOrder();

    return {
      props,
      emit,
      state,
      refreshOrder,
    };
  },
});
