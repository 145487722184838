
import { defineComponent, onMounted, PropType, reactive, ref } from "vue";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus";
import Notify from "@/modules/common/helpers/Notify";
import { OrderItem } from "@/modules/orders/interfaces/OrderItemsResponse.interface";

interface ComponentData {
  submitButtonLoading: boolean;
  pickedItems: number[];
}

export default defineComponent({
  name: "OrderReshipLostParcelModal",

  components: {
    FormSubmitButton,
    ModalComponent,
  },
  props: {
    items: {
      required: true,
      type: Object as PropType<OrderItem[]>,
    },
  },

  emits: ["done", "hide"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
      pickedItems: [],
    });
    const modal = ref<typeof ModalComponent | null>(null);

    const reshipItems = () => {
      state.submitButtonLoading = true;
      ApiService.post(`order/reship-parcel`, {
        groups: [state.pickedItems],
      })
        .then(() => {
          state.submitButtonLoading = false;
          ElMessage.success("Reship lost parcel request is sent.");
          modal.value?.hide();
          emit("done");
        })
        .catch((error) => {
          Notify.apiError(error);
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    };

    onMounted(() => {
      for (let item of props.items) {
        state.pickedItems[state.pickedItems.length] = item.id;
      }
    });

    return {
      state,
      props,
      reshipItems,
      modal,
    };
  },
});
