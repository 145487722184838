
import { defineComponent, PropType, reactive } from "vue";
import OrderReturnInstructionsModal from "@/modules/orders/widgets/OrderManagement/OrderReturnInstructionsModal.vue";

interface ComponentData {
  isReturnInstructionsModal: boolean;
}

export default defineComponent({
  name: "OrderReturnInstructionsButton",
  components: { OrderReturnInstructionsModal },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  emits: ["updated"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      isReturnInstructionsModal: false,
    });

    function done() {
      state.isReturnInstructionsModal = false;
      emit("updated");
    }

    return {
      state,
      props,
      done,
    };
  },
});
