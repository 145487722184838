<template>
  <modal-component
    id="order-split-modal"
    ref="modal"
    :close-on-background-click="false"
    @hide="() => $emit('hide')"
  >
    <template #complexHeader>
      <div class="row">
        <div class="col-4"></div>
        <div class="col-4 text-center">
          <h5>Order splitting</h5>
        </div>
        <div class="col-4">
          <div>
            <h6 v-if="splitThreshold !== 0" class="float-end">
              Split Threshold:
              <span> {{ $filters.currencyEUR(splitThreshold) }}</span>
            </h6>
          </div>
        </div>
      </div>
    </template>
    <template #default>
      <div class="row">
        <div class="col-9" style="border-right: 1px solid #eee">
          <div class="row">
            <div class="col text-center">
              <h4 class="text-center">Original order</h4>
            </div>
          </div>
          <table class="table">
            <thead>
              <tr>
                <td></td>
                <th colspan="2">Product</th>
                <th>Attribute</th>
                <th>Reference</th>
                <th>Price</th>
                <th>Group</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="item.id">
                <td>{{ ++index }}</td>
                <td>
                  <img
                    v-if="item.image_url != null"
                    :src="item.image_url"
                    :alt="item.title"
                    width="40"
                  />
                </td>
                <td>
                  {{ item.title }}
                </td>
                <td>
                  {{ item.variation_title }}
                </td>
                <td>
                  {{ item.reference }}
                </td>
                <td>
                  {{ item.price }}
                </td>
                <td>
                  <select
                    class="form-select form-select-sm"
                    data-previous-group-id=""
                    @change="($event) => changeItemGroup(item.id, $event)"
                  >
                    <option value="">Choose group</option>
                    <option
                      v-for="group in state.groupsList"
                      :key="group.id"
                      :value="group.id"
                    >
                      {{ group.charName }}
                    </option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-3">
          <div class="row">
            <div class="col text-center">
              <h4>Groups</h4>
            </div>
          </div>
          <table class="table">
            <thead>
              <tr class="text-center">
                <th>Group</th>
                <th>Total items</th>
                <th>Total price</th>
              </tr>
            </thead>
            <tbody v-for="group in state.groupsList" :key="group.id">
              <tr
                v-if="group.items.length > 0"
                class="text-center"
                :class="{ 'table-danger': group.groupPrice > splitThreshold }"
              >
                <td>
                  {{ group.charName }}
                </td>
                <td>
                  {{ group.items.length }}
                </td>
                <td>
                  <span
                    :class="{
                      'text-danger': group.groupPrice > splitThreshold,
                    }"
                    >{{ $filters.currencyEUR(group.groupPrice) }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <form-submit-button
          :loading="state.submitButtonLoading"
          :disabled="state.disabledSendButton"
          @click="() => splitOrder()"
        >
          Split order
        </form-submit-button>
      </div>
    </template>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive, ref } from "vue";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import { OrderItem } from "@/modules/orders/interfaces/OrderItemsResponse.interface";
import Notify from "@/modules/common/helpers/Notify";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus";

interface groupListItem {
  id: number;
  charName: string;
  items: OrderItem[];
  groupPrice: number;
}

interface ComponentData {
  submitButtonLoading: boolean;
  groupsList: groupListItem[];
  pickedOrders: [];
  disabledSendButton: boolean;
}

export default defineComponent({
  name: "OrderSplitModal",
  components: {
    FormSubmitButton,
    ModalComponent,
  },
  props: {
    items: {
      required: true,
      type: Object as PropType<OrderItem[]>,
    },
    splitThreshold: {
      required: false,
      type: Number,
      default: 0,
    },
  },

  emits: ["orderSplitted", "hide"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
      groupsList: [],
      pickedOrders: [],
      disabledSendButton: true,
    });
    const modal = ref<typeof ModalComponent | null>(null);

    const changeItemGroup = (itemId, event) => {
      let previousGroupId = event.target.getAttribute("data-previous-group-id");
      let currentGroupId = event.target.value;

      let pickedItem = props.items.filter((item) => {
        return item.id === itemId;
      })[0];

      if (previousGroupId != "") {
        // Deleting an item from previous group:
        let jma = state.groupsList[previousGroupId].items.findIndex(
          (item) => item.id === itemId
        );

        state.groupsList[previousGroupId].items.splice(jma, 1);
        state.groupsList[previousGroupId].groupPrice -= pickedItem.price;
      }

      if (currentGroupId != "") {
        // Adding an item into current group:
        let endOfArray = state.groupsList[currentGroupId].items.length;
        state.groupsList[currentGroupId].items[endOfArray] = pickedItem;
        state.groupsList[currentGroupId].groupPrice += Number(pickedItem.price);
      }

      event.target.setAttribute("data-previous-group-id", event.target.value);

      state.disabledSendButton = checkDisableSubmitButton();
    };

    const checkDisableSubmitButton = (): boolean => {
      for (let group of state.groupsList) {
        if (group.items.length > 0) {
          return false;
        }
      }

      return true;
    };

    const splitOrder = () => {
      let totalOrderItemsByGroups: [number[]] = [[]];
      let ind = 0;
      //let totalSplittedItems = 0;
      for (let item of state.groupsList) {
        if (item.items.length > 0) {
          totalOrderItemsByGroups[ind] = [];
          for (let orderItem of item.items) {
            totalOrderItemsByGroups[ind][totalOrderItemsByGroups[ind].length] =
              orderItem.id;
            //totalSplittedItems++;
          }
          ind++;
        }
      }

      // TODO::: It will required later
      /**
       if (totalSplittedItems != props.items.length) {
        Notify.apiError({
          name: "groupDistributionError",
          message: "Please, distribute each item into groups!",
        });
      }
       */

      ApiService.post(`order/split`, {
        groups: totalOrderItemsByGroups,
      })
        .then(() => {
          state.submitButtonLoading = false;
          ElMessage.success("Order splitted.");
          modal.value?.hide();
          emit("orderSplitted");
        })
        .catch((error) => {
          Notify.apiError(error);
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    };

    const setGroupsList = () => {
      // Function fills groupsList array with Letters A-Z:
      let id = 0;
      for (let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); ++i, id++) {
        state.groupsList[state.groupsList.length] = {
          id: id,
          items: [],
          charName: String.fromCharCode(i),
          groupPrice: 0,
        };
      }
    };
    setGroupsList();

    return {
      state,
      props,
      modal,
      changeItemGroup,
      splitOrder,
    };
  },
});
</script>

<style scoped></style>
