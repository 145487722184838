<template>
  <modal-component
    id="order-set-tracking-number-modal"
    ref="modal"
    size="large"
    :close-on-background-click="false"
    @hide="() => $emit('hide')"
  >
    <template #title>Shipping tracking</template>
    <template #default>
      <div class="row mb-4">
        <div class="col-3 d-flex align-items-center offset-1">Carrier</div>
        <div class="col-3">
          <select
            id="select-carrier"
            v-model="state.carrier"
            class="form-control form-select"
            name="carrier"
          >
            <option selected="selected" value="">Select carrier</option>
            <option value="DHL">DHL</option>
            <option value="UPS">UPS</option>
            <option value="FEDEX">FedEx</option>
            <option value="DEUTSCHE_POST">Deutsche Post</option>
          </select>
        </div>
        <div class="col-3 my-auto">
          <button
            class="btn btn-success"
            type="button"
            @click="() => addParcel()"
          >
            <i class="fas fa-plus" style=""></i> Add parcel
          </button>
        </div>
        <div class="col-2 my-auto">
          <h6>{{ state.pickedParcels.length }} parcels total</h6>
        </div>
      </div>
      <div
        v-for="(parcel, parcelCounter) in state.pickedParcels"
        :key="parcel.id"
      >
        <div class="row d-flex align-items-center mb-4">
          <div class="col-1">
            <div class="">{{ parcelCounter + 1 }}</div>
          </div>
          <div class="col-3">
            <div class="">Tracking Number</div>
          </div>
          <div class="col-3">
            <input
              v-model="parcel.trackingNumber"
              type="text"
              class="form-control"
            />
          </div>
          <div class="col-2">Parcel Type</div>
          <div class="col-2">
            <select
              id="items_bulk_actions"
              v-model="parcel.value"
              class="form-select"
            >
              <option
                v-for="type in state.parcelLabels"
                :key="type.id"
                :value="type.id"
              >
                {{ type.type + " " + type.name }}
              </option>
            </select>
          </div>
          <div v-if="parcel.id !== 0" class="col-1">
            <button
              type="button"
              class="btn btn-sm quantity_button"
              @click="() => deleteParcel(parcel.id)"
            >
              <i class="fas text-danger fa-times" style="padding-right: 0"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="row my-5">
        <hr />
        <div class="col text-center">
          <h4 class="mt-2">Return tracking</h4>
        </div>
      </div>
      <div class="row">
        <div class="row d-flex align-items-center mb-4">
          <div class="col-3 offset-1">
            <div class="">Tracking Number</div>
          </div>
          <div class="col-3">
            <input
              v-model="state.returnTrackingNumber"
              type="text"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <form-submit-button
          :loading="state.submitButtonLoading"
          :disabled="!isSubmit"
          @click="() => sendTrackingNumber()"
        >
          Save
        </form-submit-button>
      </div>
    </template>
  </modal-component>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  computed,
} from "vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import { TrackingHistory } from "@/modules/orders/interfaces/OrderTrackingHistory.interface";
import { ElMessage } from "element-plus";

interface ParcelLabel {
  id: number;
  name: string;
  type: string;
}

interface PickedParcel {
  id: number;
  value: string;
  trackingNumber: string;
}

interface RequestData {
  parcel_type: string[];
  tracking_number: string[];
  carrier: string;
  return_tracking_number: string;
}

interface ComponentData {
  submitButtonLoading: boolean;
  pickedParcels: PickedParcel[];
  parcelId: number;
  carrier: string;
  parcelLabels?: ParcelLabel[];
  returnTrackingNumber: string;
}

export default defineComponent({
  name: "OrderSetTrackingNumberModal",
  components: { ModalComponent, FormSubmitButton },
  props: {
    id: {
      type: Number,
      required: true,
    },
    orderTracking: {
      type: Object as PropType<TrackingHistory[]>,
      required: false,
      default: null,
    },
  },

  emits: ["updated", "hide"],

  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
      carrier: "",
      pickedParcels: [
        {
          id: 0,
          value: "",
          trackingNumber: "",
        },
      ],
      parcelId: 0,
      parcelLabels: [],
      returnTrackingNumber: "",
    });

    const addParcel = () => {
      state.parcelId++;
      state.pickedParcels.push({
        id: state.parcelId,
        value: "",
        trackingNumber: "",
      });
    };

    const deleteParcel = (id: number) => {
      let index = state.pickedParcels.findIndex((item) => item.id === id);
      state.pickedParcels.splice(index, 1);
    };

    const modal = ref<typeof ModalComponent | null>(null);

    const sendTrackingNumber = () => {
      let parcelsData = state.pickedParcels;

      let requestData: RequestData = {
        parcel_type: [],
        tracking_number: [],
        carrier: state.carrier,
        return_tracking_number: state.returnTrackingNumber,
      };

      for (let item in parcelsData) {
        requestData.parcel_type[item] = parcelsData[item]["value"];
        requestData.tracking_number[item] = parcelsData[item]["trackingNumber"];
      }

      state.submitButtonLoading = true;
      ApiService.put(`order/${props.id}/tracking-number`, requestData)
        .then(({ data }) => {
          ElMessage.success("Tracking codes changed successfully.");
          emit("updated");
          modal.value?.hide();
        })
        .catch((error) => Notify.apiError(error))
        .finally(() => {
          state.submitButtonLoading = false;
        });
    };

    const getParcelLabels = () => {
      ApiService.get(`shipping-label`)
        .then(({ data }) => {
          state.parcelLabels = data.parcelTypes;
        })
        .catch((error) => Notify.apiError(error));
    };

    getParcelLabels();

    onMounted(() => {
      if (props.orderTracking?.length > 0) {
        for (let item in props.orderTracking) {
          if (!props.orderTracking[item].is_return) {
            state.parcelId = +item;
            state.pickedParcels[item] = {
              id: state.parcelId,
              value: props.orderTracking[item].type,
              trackingNumber: props.orderTracking[item].code,
            };
          } else {
            state.returnTrackingNumber = props.orderTracking[item].code;
          }
        }

        state.carrier = props.orderTracking[0].carrier;
      }
    });

    const isSubmit = computed<boolean>(() => {
      if (state.carrier == "") {
        return false;
      }
      for (let item of state.pickedParcels) {
        if (item.value == "" || item.trackingNumber == "") {
          return false;
        }
      }

      return true;
    });

    return {
      emit,
      state,
      props,
      modal,
      addParcel,
      deleteParcel,
      sendTrackingNumber,
      isSubmit,
    };
  },
});
</script>

<style scoped>
.quantity_button i {
  font-size: 1.4rem;
  padding: 0.25rem;
}
</style>
