
import {defineComponent, PropType} from "vue";
import {PreviousOrder} from "@/modules/common/interfaces/PreviousOrdersResponse.interface";
import OrderStatusBadge from "@/modules/orders/widgets/OrderStatusBadge.vue";
import OrderMarkBadge from "@/modules/orders/widgets/OrderMarkBadge.vue";

export default defineComponent({
  name: "PreviousOrdersTable",
  components: {OrderMarkBadge, OrderStatusBadge},
  props: {
    items: {
      type: Object as PropType<PreviousOrder[]>,
      required: true,
    },
    currentId: {
      type: Number,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const getRowClassName = ({row}) => {
      if (props.currentId != null && row.id === props.currentId) {
        return "bg-secondary";
      }
      return "";
    };

    return {getRowClassName};
  },
});
