<template>
  <span class="badge text-white" :style="'background-color:' + status.color">{{
    status.title || status.name
  }}</span>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { OrderStatus } from "@/modules/orders/interfaces/OrderDetails.interface";

export default defineComponent({
  name: "OrderStatusBadge",
  props: {
    status: {
      type: Object as PropType<OrderStatus>,
      required: true,
    },
  },
});
</script>

<style scoped></style>
