<template>
  <card-component>
    <template #header>
      <span>Order Invoices</span>
    </template>
    <template #toolbar>
      <custom-button
        v-if="state.hasMissingInvoices"
        class="btn-danger btn-sm"
        :loading="state.generatingMissingInvoices"
        @click="createMissingInvoices"
      >
        Create missing invoices
      </custom-button>
    </template>
    <template #default>
      <div v-if="state.loading" class="text-center">
        <loading-indicator />
      </div>
      <el-table
        v-if="!state.loading && state.orderInvoices"
        :data="state.orderInvoices"
        class="table table-sm table-borderless"
        size="mini"
      >
        <el-table-column label="Type, #">
          <template #default="{ row }">
            <a v-if="row.url" :href="$filters.appendTokenToUrl(row.url)" target="_blank">
              {{ row.type + row.number }}
              {{ row.status === "DRAFT" ? " (DRAFT)" : "" }}
            </a>
            <div v-if="!row.url">{{ row.type + row.number }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="issue_date" label="Date" />
        <el-table-column label="Amount" width="80">
          <template #default="{ row }">
            <div>
              {{ $filters.currencyEUR(row.amount) }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </card-component>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import ApiService from "@/core/services/ApiService";
import CardComponent from "@/components/card/CardComponent.vue";
import LoadingIndicator from "@/components/spinners/LoadingIndicator.vue";
import { ElMessageBox } from "element-plus";
import CustomButton from "@/components/buttons/CustomButton.vue";
import Notify from "@/modules/common/helpers/Notify";

interface OrderInvoice {
  amount: number;
  id: number;
  issue_date: string;
  type: string;
  status: string;
  url?: string;
}

interface ComponentData {
  loading: boolean;
  error?: unknown;
  orderInvoices?: OrderInvoice[];
  hasMissingInvoices: boolean;
  generatingMissingInvoices: boolean;
}

export default defineComponent({
  name: "OrderInvoicesWidget",
  components: {
    CustomButton,
    LoadingIndicator,
    CardComponent,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  setup(props, { expose }) {
    const state = reactive<ComponentData>({
      loading: true,
      error: undefined,
      orderInvoices: undefined,
      hasMissingInvoices: false,
      generatingMissingInvoices: false,
    });

    const resetState = (): void => {
      state.loading = true;
      state.error = undefined;
    };

    const loadData = (): void => {
      resetState();
      ApiService.get(`order/${props.id}/invoices`)
        .then(({ data }) => {
          state.orderInvoices = data.invoices;
          state.hasMissingInvoices = data.isOrderBalanced !== true;
        })
        .catch((error) => {
          state.error = error;
        })
        .finally(() => {
          state.loading = false;
        });
    };

    const createMissingInvoices = (): void => {
      ElMessageBox.confirm(
        "This will recreate missing RBF invoices. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          state.generatingMissingInvoices = true;

          ApiService.post(`order/${props.id}/invoices/fix`)
            .then(() => loadData())
            .catch((error) => Notify.apiError(error))
            .finally(() => {
              state.generatingMissingInvoices = false;
            });
        })
        .catch(() => {
          // do nothing
        });
    };

    onMounted(loadData);

    expose({
      loadData,
    });

    return {
      state,
      props,
      createMissingInvoices,
    };
  },
});
</script>

<style scoped></style>
