<template>
  <div v-if="state.id != null">
    <!-- LAYOUTS START -->
    <div v-if="state.layout">
      <div v-if="state.layout === 'packing'">
        <order-packing-layout
          :id="state.id"
          :order-tracking="state.orderTracking"
          @updated="() => refreshOrder()"
        />
      </div>
      <div v-else-if="state.layout === 'tracking'">
        <order-tracking-layout
          :id="state.id"
          :order-tracking="state.orderTracking"
          @updated="() => refreshOrder()"
        />
      </div>
      <div v-else>
        <order-basic-layout
          :id="state.id"
          :order-tracking="state.orderTracking"
          @updated="() => refreshOrder()"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from "vue";
import { useRoute } from "vue-router";
import { Order } from "@/modules/orders/interfaces/OrderDetails.interface";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import { OrderBalance } from "@/modules/orders/interfaces/OrderBalance.interface";
import { OrderPaymentsResponse } from "@/modules/orders/interfaces/OrderPaymentsResponse.interface";
import { OrderTrackingHistory } from "@/modules/orders/interfaces/OrderTrackingHistory.interface";
import { setPageTitle } from "@/core/helpers/dom";
import OrderBasicLayout from "@/modules/orders/layouts/Order/OrderBasicLayout.vue";
import OrderTrackingLayout from "@/modules/orders/layouts/Order/OrderTrackingLayout.vue";
import OrderPackingLayout from "@/modules/orders/layouts/Order/OrderPackingLayout.vue";

interface ComponentData {
  order?: Order;
  id: number | null;
  orderBalance?: OrderBalance | null;
  orderPayments?: OrderPaymentsResponse | null;
  orderTracking?: OrderTrackingHistory;
  layout: string;
}

export default defineComponent({
  name: "OrdersViewPage",
  components: {
    OrderPackingLayout,
    OrderTrackingLayout,
    OrderBasicLayout,
  },

  setup(props) {
    const state = reactive<ComponentData>({
      order: undefined,
      id: null,
      orderTracking: {
        tracking_history: null,
        tracking_current: null,
        tracking_validator: null,
      },
      layout: "",
    });
    const route = useRoute();

    setPageTitle("Order #" + route.params.id.toString());

    route.params.id.toString();

    const getOrderTracking = () => {
      ApiService.get(`order/${state.id}/tracking-history`)
        .then(({ data }) => {
          state.orderTracking = data;
        })
        .catch((error) => {
          Notify.apiError(error);
        });
    };

    const refreshOrder = () => {
      getOrderTracking();
    };

    const setOrderId = () => {
      if (isNaN(+route.params.id)) {
        ApiService.get(`order/${route.params.id}/id`)
          .then((data) => {
            state.id = data.data.id;
            refreshOrder();
          })
          .catch((error) => {
            Notify.apiError(error);
          });
      } else {
        state.id = +route.params.id;
        refreshOrder();
      }
    };

    setOrderId();

    if (localStorage.layout) {
      state.layout = localStorage.layout;
    }

    watch(
      () => route.params.id,
      () => {
        if (isNaN(+route.params.id)) {
          setOrderId();
        } else {
          state.id = +route.params.id;
        }
      }
    );

    return {
      state,
      props,
      route,
      refreshOrder,
    };
  },
});
</script>

<style>
.el-table .cell {
  line-height: 1rem !important;
}

.el-pagination {
  margin-top: -1rem;
}
</style>
