
import { defineComponent, PropType, reactive } from "vue";
import OrderAddItemModal from "@/modules/orders/widgets/OrderItems/OrderAddItemModal.vue";

interface ComponentData {
  isAddItemModal: boolean;
}

export default defineComponent({
  name: "OrderAddItemsButton",
  components: { OrderAddItemModal },
  props: {
    id: {
      type: Number,
      required: true,
    },
    isDisabled: {
      required: true,
      type: Boolean as PropType<boolean>,
    },
  },

  emits: ["updated"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      isAddItemModal: false,
    });

    function itemsAdded() {
      state.isAddItemModal = false;
      emit("updated");
    }
    return {
      state,
      props,
      itemsAdded,
    };
  },
});
