
import { defineComponent } from "vue";

export default defineComponent({
  name: "OrderViewInvoiceButton",
  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const windUrl = process.env.VUE_APP_BASIC_URL;

    return {
      props,
      windUrl,
    };
  },
});
