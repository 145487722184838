
import { defineComponent, reactive, ref } from "vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus";
import Notify from "@/modules/common/helpers/Notify";

interface ComponentData {
  submitButtonLoading: boolean;
}

export default defineComponent({
  name: "OrderDeleteMessagesModal",
  components: {
    ModalComponent,
    FormSubmitButton,
  },
  props: {
    messageId: {
      type: Number,
      required: true,
    },
  },

  emits: ["deleted", "hide"],

  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
    });
    const modal = ref<typeof ModalComponent | null>(null);

    const deleteOrderMessage = () => {
      state.submitButtonLoading = true;
      ApiService.delete(`order/messages/${props.messageId}`)
        .then((data) => {
          state.submitButtonLoading = false;
          ElMessage.success("Order message was deleted.");
          modal.value?.hide();
          emit("deleted");
        })
        .catch((error) => Notify.apiError(error))
        .finally(() => {
          state.submitButtonLoading = false;
        });
    };
    return {
      state,
      props,
      modal,
      deleteOrderMessage,
    };
  },
});
