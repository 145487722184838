
import { defineComponent, onMounted, reactive, watch } from "vue";
import CardComponent from "../../../../components/card/CardComponent.vue";
import {
  OrderStatusHistory,
  OrderStatusHistoryResponse,
} from "@/modules/orders/interfaces/OrderStatusHistoryResponse.interface";
import ApiService from "@/core/services/ApiService";
import LoadingIndicator from "@/components/spinners/LoadingIndicator.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import RequestFailedError from "@/components/errors/RequestFailedError.vue";
import OrderStatusHistoryForm from "@/modules/orders/widgets/OrderStatusHistory/OrderStatusHistoryForm.vue";
import OrderStatusBadge from "@/modules/orders/widgets/OrderStatusBadge.vue";
import { ElMessageBox } from "element-plus";

interface ComponentData {
  loading: boolean;
  error?: unknown;
  response?: OrderStatusHistoryResponse;
  escalateStatus: number | null;
}

export default defineComponent({
  name: "OrderStatusHistoryWidget",
  components: {
    OrderStatusBadge,
    OrderStatusHistoryForm,
    CardComponent,
    LoadingIndicator,
    RequestFailedError,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  emits: ["updated"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      loading: true,
      error: undefined,
      response: undefined,
      escalateStatus: 3,
    });

    watch(
      () => props.id,
      () => {
        loadData();
      }
    );

    const getUserName = (row: OrderStatusHistory): string => {
      if (row.user != null) {
        return row.user.name;
      }
      return "Unknown #" + row.employee_id;
    };

    const statusUpdated = () => {
      emit("updated");
      loadData();
    };

    const sendEscalate = () => {
      ElMessageBox.confirm(
        "This will update order's status. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          sendRequest();
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const sendRequest = (): boolean => {
      ApiService.post(`order/${props.id}/status-history`, {
        status_id: state.escalateStatus,
      })
        .then(() => {
          statusUpdated();
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            text: error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        })
        .finally(() => {
          state.loading = false;
        });

      return true;
    };

    const resetState = () => {
      state.response = undefined;
      state.loading = true;
      state.error = undefined;
    };

    const loadData = () => {
      resetState();
      ApiService.get(`order/${props.id}/status-history`)
        .then(({ data }: { data: OrderStatusHistoryResponse }) => {
          state.response = data;
        })
        .catch((error) => {
          state.error = error;
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(() => {
      loadData();
    });

    return {
      state,
      props,
      getUserName,
      statusUpdated,
      loadData,
      sendEscalate,
      resetState,
    };
  },
});
