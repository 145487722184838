<template>
  <button
    type="button"
    class="btn btn-sm btn-secondary"
    @click="
      () => {
        state.isReshipLostParcelModal = true;
      }
    "
  >
    Reship lost parcel
  </button>

  <OrderReshipLostParcelModal
    v-if="state.isReshipLostParcelModal === true"
    :items="items"
    @hide="
      () => {
        state.isReshipLostParcelModal = false;
      }
    "
    @done="() => done()"
  />
</template>

<script lang="ts">
import { defineComponent, PropType, reactive } from "vue";
import OrderReshipLostParcelModal from "@/modules/orders/widgets/OrderManagement/OrderReshipLostParcelModal.vue";
import { OrderItem } from "@/modules/orders/interfaces/OrderItemsResponse.interface";

interface ComponentData {
  isReshipLostParcelModal: boolean;
}

export default defineComponent({
  name: "OrderReshipLostParcelButton",
  components: { OrderReshipLostParcelModal },
  props: {
    items: {
      required: true,
      type: Object as PropType<OrderItem[]>,
    },
  },

  emits: ["updated"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      isReshipLostParcelModal: false,
    });

    function done() {
      state.isReshipLostParcelModal = false;
      emit("updated");
    }

    return {
      state,
      props,
      done,
    };
  },
});
</script>

<style scoped></style>
