
import { defineComponent, onMounted, reactive, ref } from "vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import { ErrorMessage, Field, useForm } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import { ElMessage } from "element-plus";

interface Product {
  id: number;
  name: string;
  product_id: number;
  image_id: number;
  manufacturer: string;
  image_url: string;
  image_dialog_url: string;
}

interface PickedProduct {
  id: number;
  price: number;
  final_price: number;
  discount: number;
  product_variation_id: number;
  product_id: number;
  quantity: number;
  name: string;
  image_url: string;
}

interface Options {
  name: string;
  value: string;
}

interface Variation {
  id: number;
  variation: string;
  price: number;
}

interface ComponentData {
  pickedProducts?: PickedProduct[];
  pickedProduct?: Product | number | null;
  products: Product[] | null;
  pickedVariation: number | null;
  variations: Variation[] | null;
  productsOptions: Options[] | null;
  variationOptions: Options[] | null;
  isLoadingProducts: boolean;
  imageUrl?: string | null;
  submitButtonLoading: boolean;
  addressId: number | null;
  quantity: number;
  price: number;
  finalPrice: number;
  reductionPercent: number;
  disabledSendButton: boolean;
}

export default defineComponent({
  name: "OrderAddItemModal",

  components: {
    Multiselect,
    Field,
    ErrorMessage,
    FormSubmitButton,
    ModalComponent,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  emits: ["itemAdded", "hide"],

  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      pickedProducts: [],
      pickedProduct: null,
      pickedVariation: null,
      products: [],
      variations: [],
      productsOptions: [],
      variationOptions: [],
      isLoadingProducts: false,
      imageUrl: null,
      submitButtonLoading: false,
      addressId: null,
      quantity: 1,
      price: 0,
      finalPrice: 0,
      reductionPercent: 0,
      disabledSendButton: true,
    });
    const modal = ref<typeof ModalComponent | null>(null);
    const { setFieldValue, handleSubmit, setFieldError } = useForm();

    /** Filtering Start **/
    let changeTimer: number | undefined;
    let currentPickedItemId = 0;
    const maxQuantity = 999;

    const resetTimer = () => {
      if (changeTimer != null) {
        clearTimeout(changeTimer);
        changeTimer = undefined;
      }
    };

    // const runTimer = () => {
    //   resetTimer();
    //   changeTimer = setTimeout(() => {
    //     // asyncFindProduct()
    //   }, 2000);
    // };

    const asyncFindProduct = (query): void => {
      if (query.length !== 0) {
        state.products = [];
        state.productsOptions = [];
        state.pickedProduct = null;
        state.isLoadingProducts = true;

        resetTimer();

        changeTimer = setTimeout(() => {
          ApiService.get(`products/?search=` + query)
            .then(({ data }) => {
              state.products = data;
              state.pickedProduct = null;

              state.productsOptions = data.map((item) => ({
                name: item.name + " (#" + item.product_id + ")",
                value: item.product_id,
              }));
            })
            .catch((error) => Notify.apiError(error))
            .finally(() => {
              state.isLoadingProducts = false;
            });
        }, 500);
      }
    };

    const changeProduct = (productId: number): void => {
      state.imageUrl = state.products?.find(
        (item) => item.product_id === productId
      )?.image_url;

      state.price = 0;
      state.finalPrice = 0;
      state.pickedVariation = null;
      state.variations = [];
      state.variationOptions = [];
      if (productId != null) {
        getProductVariations(productId);
        setFieldError("pickedProduct", undefined);
      }
    };

    const deleteItem = (itemId: number): void => {
      if (state.pickedProducts != undefined) {
        let index = state.pickedProducts.findIndex(
          (item) => item.id === itemId
        );
        state.pickedProducts.splice(index, 1);

        if (state.pickedProducts.length === 0) {
          state.disabledSendButton = true;
        }
      }
    };

    const getProductVariations = (value): void => {
      if (value !== null) {
        ApiService.get(`products/` + value + `/variations`)
          .then(({ data }) => {
            state.variations = data;
            state.variationOptions = data.map((item) => ({
              name: item.variation,
              value: item.id,
            }));
          })
          .catch((error) => Notify.apiError(error));
      }
    };

    const changeVariation = (productVariationId: number): void => {
      state.price = 0;
      state.finalPrice = 0;
      if (productVariationId != null) getProductPrice(productVariationId);

      setFieldError("pickedVariation", undefined);
    };

    const getProductPrice = (productVariationId): void => {
      ApiService.get(
        `products/` + productVariationId + `/price/` + state.addressId
      )
        .then(({ data }) => {
          state.price = data.price;
          state.finalPrice = data.price;
          adjustFinalPrice();
        })
        .catch((error) => Notify.apiError(error));
    };

    const addItem = handleSubmit(() => {
      if (state.pickedProducts?.length != 0) {
        state.submitButtonLoading = true;

        ApiService.post(`order/` + props.id.toString() + "/items", {
          items: state.pickedProducts,
        })
          .then(() => {
            state.submitButtonLoading = false;
            ElMessage.success("Item added.");
            modal.value?.hide();
            emit("itemAdded");
          })
          .catch((error) => {
            Notify.apiError(error);
          })
          .finally(() => {
            state.submitButtonLoading = false;
          });
      } else {
        throwValidationError();
      }
    });

    const getCountry = (): void => {
      ApiService.get(`order/` + props.id.toString() + "/address")
        .then(({ data }) => {
          state.addressId = data.address.country_id;
        })
        .catch((error) => Notify.apiError(error));
    };

    const integerFilter = ($event): void => {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode === 46) {
        $event.preventDefault();
      }

      if (state.quantity >= maxQuantity) {
        $event.preventDefault();
      }
    };

    const validateQuantity = (): void => {
      if (state.quantity > maxQuantity) {
        state.quantity = maxQuantity;
      }
    };

    const adjustFinalPrice = (): void => {
      if (state.reductionPercent >= 100 || state.finalPrice <= 0) {
        throwValidationError("correct reduction percent");
      }
      state.finalPrice = +(
        state.price -
        state.price * (state.reductionPercent / 100)
      ).toFixed(2);
    };

    const adjustReductionPercent = (): void => {
      if (state.finalPrice > 0 && state.price != 0) {
        state.reductionPercent = +(
          100 -
          (state.finalPrice / state.price) * 100
        ).toFixed(2);
      }
    };

    const throwValidationError = (
      message = "product, variation, quantity and final price"
    ): void => {
      Notify.apiError({
        name: "notSelectedProductOrVariation",
        message: "Please select " + message,
      });
    };

    const validatingFields = (): void => {
      let errors: string[] = [];

      if (state.pickedProduct == null) {
        errors[errors.length] = "product";
        setFieldError("pickedProduct", "Pick product");
      } else {
        setFieldError("pickedProduct", undefined);
      }

      if (state.pickedVariation == null) {
        errors[errors.length] = "variation";
        setFieldError("pickedVariation", "Pick variation");
      } else {
        setFieldError("pickedVariation", undefined);
      }

      if (Number(state.quantity) <= 0 || isNaN(state.quantity)) {
        errors[errors.length] = "quantity";
        setFieldError("quantity", "Wrong quantity");
      } else {
        setFieldError("quantity", undefined);
      }

      if (Number(state.price) <= 0 || isNaN(state.price)) {
        errors[errors.length] = "price";
        setFieldError("price", "Wrong price");
      } else {
        setFieldError("price", undefined);
      }

      if (Number(state.finalPrice) <= 0 || isNaN(state.finalPrice)) {
        errors[errors.length] = "final price";
        setFieldError("finalPrice", "Wrong final price");
      } else {
        setFieldError("finalPrice", undefined);
      }

      throwValidationError(errors.join(", "));
    };

    const pickItem = (): void => {
      if (state.pickedProducts !== undefined && state.products != null) {
        if (
          state.pickedProduct != null &&
          state.pickedVariation != null &&
          Number(state.price) > 0 &&
          Number(state.finalPrice) > 0 &&
          Number(state.quantity) > 0
        ) {
          var item = state.products.find(
            (item) => item.product_id === state.pickedProduct
          );

          state.quantity = +Number(state.quantity).toFixed();
          state.quantity = state.quantity >= 99 ? 99 : state.quantity;

          state.finalPrice = +Number(state.finalPrice).toFixed(2);
          state.reductionPercent = isNaN(state.reductionPercent)
            ? 0
            : +Number(state.reductionPercent).toFixed(2);

          currentPickedItemId++;

          if (item != undefined) {
            state.pickedProducts[state.pickedProducts.length] = {
              id: currentPickedItemId,
              price: state.price,
              final_price: state.finalPrice,
              product_variation_id: state.pickedVariation,
              product_id: item.product_id,
              quantity: state.quantity,
              name: item.name,
              image_url: item.image_url,
              discount: state.reductionPercent,
            };
          }

          state.disabledSendButton = false;
        } else {
          state.disabledSendButton = true;
          validatingFields();
        }
      }
    };

    onMounted(getCountry);

    return {
      state,
      asyncFindProduct,
      getProductVariations,
      getProductPrice,
      changeProduct,
      changeVariation,
      getCountry,
      addItem,
      modal,
      pickItem,
      deleteItem,
      adjustFinalPrice,
      adjustReductionPercent,
      integerFilter,
      validateQuantity,
    };
  },
});
