<template>
  <modal-component
    id="order-add-item-modal"
    ref="modal"
    size="large"
    @hide="$emit('hide')"
  >
    <template #title> Deleting items</template>
    <template #default>
      <form @submit="deleteItems">
        <div class="row">
          <table class="table">
            <thead>
              <tr>
                <th class="text-center" colspan="2">Product</th>
                <th class="text-center">Reason</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="item.id">
                <td>
                  <img
                    v-if="item.image_url != null"
                    :src="item.image_url"
                    :alt="item.title"
                    width="80"
                  />
                </td>
                <td>
                  {{ item.title }}
                </td>
                <td>
                  <select
                    v-if="state.resultItems[index].reason_id !== undefined"
                    v-model="state.resultItems[index].reason_id"
                    class="form-select"
                    @change="updateReasons"
                  >
                    <option value="0">Choose reason</option>
                    <option
                      v-for="itemReasons in state.itemDeleteReasons"
                      :key="itemReasons.id"
                      :value="itemReasons.id"
                    >
                      {{ itemReasons.name }}
                    </option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <form-submit-button :loading="state.submitButtonLoading">
            Delete items
          </form-submit-button>
        </div>
      </form>
    </template>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, reactive, ref } from "vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import { useForm } from "vee-validate";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import { OrderItem } from "@/modules/orders/interfaces/OrderItemsResponse.interface";
import { ElMessage } from "element-plus";

interface resultItems {
  reason_id: number;
  id: number;
}

interface ComponentData {
  resultItems: resultItems[];
  itemDeleteReasons:
    | {
        id: number;
        descriptions: string;
      }[]
    | null;
  submitButtonLoading: boolean;
}

export default defineComponent({
  name: "OrderItemsDeleteModal",
  components: {
    FormSubmitButton,
    ModalComponent,
  },
  props: {
    items: {
      required: true,
      type: Object as PropType<OrderItem[]>,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  emits: ["itemDeleted", "hide"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      resultItems: [],
      itemDeleteReasons: null,
      submitButtonLoading: false,
    });
    const modal = ref<typeof ModalComponent | null>(null);

    const setItems = () => {
      for (const i in props.items) {
        state.resultItems[i] = {
          id: props.items[i].id,
          reason_id: 0,
        };
      }
    };
    setItems();

    const updateReasons = (event) => {
      for (const item of state.resultItems) {
        if (item.reason_id === 0) {
          item.reason_id = event.target.value;
        }
      }
    };

    const { handleSubmit } = useForm();

    const deleteItems = handleSubmit(() => {
      for (let item of state.resultItems) {
        if (item.reason_id <= 0) {
          Notify.apiError({
            name: "notSelectedDeletingReason",
            message: "Please select deleting reason.",
          });
          return false;
        }
      }
      ApiService.post("order/" + props.id.toString() + "/items/delete", {
        items: state.resultItems,
      })
        .then(() => {
          state.submitButtonLoading = false;
          ElMessage.success("Items deleted.");
          modal.value?.hide();
          emit("itemDeleted");
        })
        .catch((error) => {
          Notify.apiError(error);
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    });

    const getItemDeleteReasons = () => {
      ApiService.get(`items/reasons`)
        .then(({ data }: { data }) => {
          state.itemDeleteReasons = data.reasons;
        })
        .catch((error) => {
          Notify.apiError(error);
        });
    };

    onMounted(() => {
      setItems();
      getItemDeleteReasons();
    });
    return {
      state,
      getItemDeleteReasons,
      updateReasons,
      deleteItems,
      modal,
    };
  },
});
</script>

<style scoped></style>
