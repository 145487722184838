<template>
  <div class="table-responsive">
    <h6>Totals:</h6>
    <table class="table table-bordered">
      <tbody v-if="voucher">
        <tr v-for="item in voucher" :key="item.id">
          <td>Voucher ({{ item.type }} {{ item.value }})</td>
          <td>
            <badge type="dark">{{ item.code }}</badge>
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td>Products</td>
          <td>{{ $filters.currencyEUR(totals.subtotal) }}</td>
        </tr>
        <tr>
          <td>Shipping</td>
          <td>{{ $filters.currencyEUR(totals.shipping) }}</td>
        </tr>
        <tr>
          <td><strong>Total</strong></td>
          <td>
            <strong>{{ $filters.currencyEUR(totals.total) }}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import {
  OrderTotals,
  VoucherData,
} from "@/modules/orders/interfaces/OrderItemsResponse.interface";
import Badge from "@/components/Badge.vue";

export default defineComponent({
  name: "OrderItemsTotals",
  components: { Badge },
  props: {
    totals: {
      type: Object as PropType<OrderTotals>,
      required: true,
    },
    voucher: {
      type: Object as PropType<VoucherData>,
      required: false,
      default: null,
    },
  },
});
</script>

<style scoped>
.table-responsive table td {
  padding: 2px;
}
</style>
