
import { defineComponent, PropType, reactive, toRefs } from "vue";
import CardComponent from "@/components/card/CardComponent.vue";
import LoadingIndicator from "@/components/spinners/LoadingIndicator.vue";
import RequestFailedError from "@/components/errors/RequestFailedError.vue";
import { OrderPaymentsResponse } from "@/modules/orders/interfaces/OrderPaymentsResponse.interface";
import { OrderBalance } from "@/modules/orders/interfaces/OrderBalance.interface";

interface ComponentData {
  loading: boolean;
}

export default defineComponent({
  name: "OrderPaymentsWidget",
  components: { LoadingIndicator, CardComponent },
  props: {
    id: {
      type: Number,
      required: true,
    },
    payments: {
      type: Object as PropType<OrderPaymentsResponse>,
      required: true,
    },
    balance: {
      type: Object as PropType<OrderBalance>,
      required: true,
    },
  },
});
