<template>
  <card-component>
    <template #header>
      <span>Refunds</span>
    </template>
    <template #toolbar> </template>
    <template #default>
      <div v-if="state.loading" class="text-center">
        <loading-indicator />
      </div>
      <request-failed-error
        v-if="state.error"
        :exception="state.error"
        @refresh="loadData"
      />
      <template v-if="state.response != null">
        <el-table
          :data="state.response.items"
          class="table table-sm table-borderless"
          size="mini"
        >
          <el-table-column prop="id" label="ID" width="90" />
          <el-table-column prop="created_at" label="Date Created" />
          <el-table-column prop="status" label="Status" width="180" />
          <el-table-column label="Amount">
            <template #default="{ row }">
              <div
                class="text-end"
                :title="
                  [
                    row.currency.iso_code,
                    $filters.currency(row.amount_currency),
                    `(${row.conversion_rate})`,
                  ].join(' ')
                "
              >
                {{ $filters.currencyEUR(row.amount_eur) }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </template>
  </card-component>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs, watch } from "vue";
import CardComponent from "@/components/card/CardComponent.vue";
import LoadingIndicator from "@/components/spinners/LoadingIndicator.vue";
import RequestFailedError from "@/components/errors/RequestFailedError.vue";
import ApiService from "@/core/services/ApiService";
import { OrderRefundsResponse } from "@/modules/orders/interfaces/OrderRefundsResponse.interface";

interface ComponentData {
  loading: boolean;
  response?: OrderRefundsResponse | undefined;
  error?: unknown;
  isRefundWithMoney: boolean;
  isRefundByVoucher: boolean;
}

export default defineComponent({
  name: "OrderRefundsWidget",
  components: {
    RequestFailedError,
    LoadingIndicator,
    CardComponent,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props, { expose }) {
    const { id } = toRefs(props);

    const state = reactive<ComponentData>({
      loading: false,
      response: undefined,
      error: undefined,
      isRefundWithMoney: false,
      isRefundByVoucher: false,
    });

    const resetState = (): void => {
      state.loading = true;
      state.response = undefined;
      state.error = undefined;
    };

    const loadData = (): void => {
      resetState();
      ApiService.get(`order/${props.id}/refunds`)
        .then(({ data }: { data: OrderRefundsResponse }) => {
          state.response = data;
        })
        .catch((error) => {
          state.error = error;
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(loadData);
    watch(id, loadData);

    expose({
      loadData,
    });

    return {
      state,
      props,
      loadData,
    };
  },
});
</script>

<style scoped></style>
