
import { defineComponent, PropType, reactive, ref } from "vue";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import { OrderItem } from "@/modules/orders/interfaces/OrderItemsResponse.interface";
import Notify from "@/modules/common/helpers/Notify";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus";

interface groupListItem {
  id: number;
  charName: string;
  items: OrderItem[];
  groupPrice: number;
}

interface ComponentData {
  submitButtonLoading: boolean;
  groupsList: groupListItem[];
  pickedOrders: [];
  disabledSendButton: boolean;
}

export default defineComponent({
  name: "OrderSplitModal",
  components: {
    FormSubmitButton,
    ModalComponent,
  },
  props: {
    items: {
      required: true,
      type: Object as PropType<OrderItem[]>,
    },
    splitThreshold: {
      required: false,
      type: Number,
      default: 0,
    },
  },

  emits: ["orderSplitted", "hide"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
      groupsList: [],
      pickedOrders: [],
      disabledSendButton: true,
    });
    const modal = ref<typeof ModalComponent | null>(null);

    const changeItemGroup = (itemId, event) => {
      let previousGroupId = event.target.getAttribute("data-previous-group-id");
      let currentGroupId = event.target.value;

      let pickedItem = props.items.filter((item) => {
        return item.id === itemId;
      })[0];

      if (previousGroupId != "") {
        // Deleting an item from previous group:
        let jma = state.groupsList[previousGroupId].items.findIndex(
          (item) => item.id === itemId
        );

        state.groupsList[previousGroupId].items.splice(jma, 1);
        state.groupsList[previousGroupId].groupPrice -= pickedItem.price;
      }

      if (currentGroupId != "") {
        // Adding an item into current group:
        let endOfArray = state.groupsList[currentGroupId].items.length;
        state.groupsList[currentGroupId].items[endOfArray] = pickedItem;
        state.groupsList[currentGroupId].groupPrice += Number(pickedItem.price);
      }

      event.target.setAttribute("data-previous-group-id", event.target.value);

      state.disabledSendButton = checkDisableSubmitButton();
    };

    const checkDisableSubmitButton = (): boolean => {
      for (let group of state.groupsList) {
        if (group.items.length > 0) {
          return false;
        }
      }

      return true;
    };

    const splitOrder = () => {
      let totalOrderItemsByGroups: [number[]] = [[]];
      let ind = 0;
      //let totalSplittedItems = 0;
      for (let item of state.groupsList) {
        if (item.items.length > 0) {
          totalOrderItemsByGroups[ind] = [];
          for (let orderItem of item.items) {
            totalOrderItemsByGroups[ind][totalOrderItemsByGroups[ind].length] =
              orderItem.id;
            //totalSplittedItems++;
          }
          ind++;
        }
      }

      // TODO::: It will required later
      /**
       if (totalSplittedItems != props.items.length) {
        Notify.apiError({
          name: "groupDistributionError",
          message: "Please, distribute each item into groups!",
        });
      }
       */

      ApiService.post(`order/split`, {
        groups: totalOrderItemsByGroups,
      })
        .then(() => {
          state.submitButtonLoading = false;
          ElMessage.success("Order splitted.");
          modal.value?.hide();
          emit("orderSplitted");
        })
        .catch((error) => {
          Notify.apiError(error);
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    };

    const setGroupsList = () => {
      // Function fills groupsList array with Letters A-Z:
      let id = 0;
      for (let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); ++i, id++) {
        state.groupsList[state.groupsList.length] = {
          id: id,
          items: [],
          charName: String.fromCharCode(i),
          groupPrice: 0,
        };
      }
    };
    setGroupsList();

    return {
      state,
      props,
      modal,
      changeItemGroup,
      splitOrder,
    };
  },
});
