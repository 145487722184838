
import { defineComponent, PropType, reactive } from "vue";
import OrderRefundWithMoneyModal from "@/modules/orders/widgets/OrderRefunds/OrderRefundWithMoneyModal.vue";
import OrderRefundByVoucherModal from "@/modules/orders/widgets/OrderRefunds/OrderRefundByVoucherModal.vue";
import { OrderPaymentsResponse } from "@/modules/orders/interfaces/OrderPaymentsResponse.interface";

interface OrderBalance {
  current: number;
  refundable: number;
  refundable_by_money: number;
}

interface ComponentData {
  isRefundWithMoney: boolean;
  isRefundByVoucher: boolean;
  isRefundWithPromotion: boolean;
}

export default defineComponent({
  name: "OrderRefundsButtonsAlert",
  components: {
    OrderRefundWithMoneyModal,
    OrderRefundByVoucherModal,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    balance: {
      type: Object as PropType<OrderBalance>,
      default: null,
    },
    payments: {
      type: Object as PropType<OrderPaymentsResponse>,
      required: true,
    },
  },

  emits: ["updated"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      isRefundWithMoney: false,
      isRefundByVoucher: false,
      isRefundWithPromotion: false,
    });

    const updateOrder = () => {
      emit("updated");
    };

    return {
      state,
      props,
      emit,
      updateOrder,
    };
  },
});
