
import {
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  computed,
} from "vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import { TrackingHistory } from "@/modules/orders/interfaces/OrderTrackingHistory.interface";
import { ElMessage } from "element-plus";

interface ParcelLabel {
  id: number;
  name: string;
  type: string;
}

interface PickedParcel {
  id: number;
  value: string;
  trackingNumber: string;
}

interface RequestData {
  parcel_type: string[];
  tracking_number: string[];
  carrier: string;
  return_tracking_number: string;
}

interface ComponentData {
  submitButtonLoading: boolean;
  pickedParcels: PickedParcel[];
  parcelId: number;
  carrier: string;
  parcelLabels?: ParcelLabel[];
  returnTrackingNumber: string;
}

export default defineComponent({
  name: "OrderSetTrackingNumberModal",
  components: { ModalComponent, FormSubmitButton },
  props: {
    id: {
      type: Number,
      required: true,
    },
    orderTracking: {
      type: Object as PropType<TrackingHistory[]>,
      required: false,
      default: null,
    },
  },

  emits: ["updated", "hide"],

  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
      carrier: "",
      pickedParcels: [
        {
          id: 0,
          value: "",
          trackingNumber: "",
        },
      ],
      parcelId: 0,
      parcelLabels: [],
      returnTrackingNumber: "",
    });

    const addParcel = () => {
      state.parcelId++;
      state.pickedParcels.push({
        id: state.parcelId,
        value: "",
        trackingNumber: "",
      });
    };

    const deleteParcel = (id: number) => {
      let index = state.pickedParcels.findIndex((item) => item.id === id);
      state.pickedParcels.splice(index, 1);
    };

    const modal = ref<typeof ModalComponent | null>(null);

    const sendTrackingNumber = () => {
      let parcelsData = state.pickedParcels;

      let requestData: RequestData = {
        parcel_type: [],
        tracking_number: [],
        carrier: state.carrier,
        return_tracking_number: state.returnTrackingNumber,
      };

      for (let item in parcelsData) {
        requestData.parcel_type[item] = parcelsData[item]["value"];
        requestData.tracking_number[item] = parcelsData[item]["trackingNumber"];
      }

      state.submitButtonLoading = true;
      ApiService.put(`order/${props.id}/tracking-number`, requestData)
        .then(({ data }) => {
          ElMessage.success("Tracking codes changed successfully.");
          emit("updated");
          modal.value?.hide();
        })
        .catch((error) => Notify.apiError(error))
        .finally(() => {
          state.submitButtonLoading = false;
        });
    };

    const getParcelLabels = () => {
      ApiService.get(`shipping-label`)
        .then(({ data }) => {
          state.parcelLabels = data.parcelTypes;
        })
        .catch((error) => Notify.apiError(error));
    };

    getParcelLabels();

    onMounted(() => {
      if (props.orderTracking?.length > 0) {
        for (let item in props.orderTracking) {
          if (!props.orderTracking[item].is_return) {
            state.parcelId = +item;
            state.pickedParcels[item] = {
              id: state.parcelId,
              value: props.orderTracking[item].type,
              trackingNumber: props.orderTracking[item].code,
            };
          } else {
            state.returnTrackingNumber = props.orderTracking[item].code;
          }
        }

        state.carrier = props.orderTracking[0].carrier;
      }
    });

    const isSubmit = computed<boolean>(() => {
      if (state.carrier == "") {
        return false;
      }
      for (let item of state.pickedParcels) {
        if (item.value == "" || item.trackingNumber == "") {
          return false;
        }
      }

      return true;
    });

    return {
      emit,
      state,
      props,
      modal,
      addParcel,
      deleteParcel,
      sendTrackingNumber,
      isSubmit,
    };
  },
});
