
import { defineComponent, reactive, ref } from "vue";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus";
import Notify from "@/modules/common/helpers/Notify";

interface ComponentData {
  submitButtonLoading: boolean;
}

export default defineComponent({
  name: "OrderReshipWrongItemsModal",

  components: {
    FormSubmitButton,
    ModalComponent,
  },
  props: {
    items: {
      required: true,
      type: [Number],
    },
  },

  emits: ["done", "hide"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
    });
    const modal = ref<typeof ModalComponent | null>(null);

    const reshipItems = () => {
      state.submitButtonLoading = true;
      ApiService.post(`order/reship-wrong-items`, {
        groups: [props.items],
      })
        .then((data) => {
          console.log("Response:", data);
          state.submitButtonLoading = false;
          ElMessage.success("Reship request is sent.");
          modal.value?.hide();
          emit("done");
        })
        .catch((error) => {
          Notify.apiError(error);
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    };

    return {
      state,
      props,
      reshipItems,
      modal,
    };
  },
});
