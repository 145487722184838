<template>
  <card-component>
    <template #header>Messages</template>
    <template #default>
      <div v-if="state.loading" class="text-center">
        <loading-indicator />
      </div>
      <request-failed-error
        v-if="state.error"
        :exception="state.error"
        @refresh="loadData"
      />
      <div v-if="state.response != null" class="messages_container">
        <div v-if="state.response.messages.length === 0" class="text-center">
          No messages
        </div>
        <order-message
          v-for="message in state.response.messages"
          :key="`message-${message.id}`"
          :message="message"
          @deleted="() => loadData()"
        />
      </div>
    </template>
    <template v-if="$filters.checkUserPermission('add_message')" #footer>
      <order-messages-form
        v-if="state.response != null"
        :id="props.id"
        @updated="() => loadData()"
      />
    </template>
  </card-component>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs, watch } from "vue";
import CardComponent from "@/components/card/CardComponent.vue";
import LoadingIndicator from "@/components/spinners/LoadingIndicator.vue";
import RequestFailedError from "@/components/errors/RequestFailedError.vue";
import ApiService from "@/core/services/ApiService";
import { OrderMessagesResponse } from "@/modules/orders/interfaces/OrderMessagesResponse.interface";
import OrderMessagesForm from "@/modules/orders/widgets/OrderMessages/OrderMessagesForm.vue";
import OrderMessage from "@/modules/orders/widgets/OrderMessages/OrderMessage.vue";

interface ComponentData {
  loading: boolean;
  error?: unknown;
  response?: OrderMessagesResponse;
}

export default defineComponent({
  name: "OrderMessagesWidget",
  components: {
    OrderMessage,
    OrderMessagesForm,
    RequestFailedError,
    LoadingIndicator,
    CardComponent,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { id } = toRefs(props);

    const state = reactive<ComponentData>({
      loading: false,
      error: false,
      response: undefined,
    });

    const resetState = (): void => {
      state.response = undefined;
      state.loading = true;
      state.error = undefined;
    };

    const loadData = () => {
      resetState();
      ApiService.get(`order/${props.id}/messages`)
        .then(({ data }: { data: OrderMessagesResponse }) => {
          state.response = data;
        })
        .catch((error) => {
          state.error = error;
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(loadData);

    watch(id, loadData);

    return { state, props, loadData };
  },
});
</script>

<style scoped>
.messages_container {
  max-height: 190px;
  overflow-y: auto;
}
</style>