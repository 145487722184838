
import { defineComponent, PropType } from "vue";
import {
  OrderTotals,
  VoucherData,
} from "@/modules/orders/interfaces/OrderItemsResponse.interface";
import Badge from "@/components/Badge.vue";

export default defineComponent({
  name: "OrderItemsTotals",
  components: { Badge },
  props: {
    totals: {
      type: Object as PropType<OrderTotals>,
      required: true,
    },
    voucher: {
      type: Object as PropType<VoucherData>,
      required: false,
      default: null,
    },
  },
});
