<template>
  <button type="button" class="btn" :disabled="loading" @click="onClick">
    <loading-indicator v-if="loading" size="sm" class="me-2" />
    <slot name="default" />
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingIndicator from "@/components/spinners/LoadingIndicator.vue";

export default defineComponent({
  name: "CustomButton",
  components: { LoadingIndicator },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["click"],
  setup(props, { emit }) {
    const onClick = (): void => {
      emit("click");
    };

    return { onClick };
  },
});
</script>

<style scoped></style>
