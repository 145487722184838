
import { defineComponent, reactive, ref } from "vue";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import { ElMessage } from "element-plus";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";

interface PickedParcel {
  id: number;
  value: string | number;
  name: string;
}

interface ParcelLabel {
  id: number;
  name: string;
  type: string;
}

interface ComponentData {
  submitButtonLoading: boolean;
  isSingleParcel: boolean;
  pickedParcels: PickedParcel[];
  parcelLabels?: ParcelLabel[];
  bagLabels?: ParcelLabel[];
  boxLabels?: ParcelLabel[];
  parcelId: number;
}

export default defineComponent({
  name: "OrderGenerateLabelModal",
  components: {
    FormSubmitButton,
    ModalComponent,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  emits: ["updated", "hide"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
      isSingleParcel: true,
      pickedParcels: [],
      parcelLabels: [],
      bagLabels: [],
      boxLabels: [],
      parcelId: 0,
    });

    const modal = ref<typeof ModalComponent | null>(null);

    const getParcelLabels = () => {
      ApiService.get(`shipping-label`)
        .then(({ data }) => {
          state.parcelLabels = data.parcelTypes;

          state.bagLabels = data.parcelTypes?.filter((item) => {
            if (item.type === "Bag") {
              return item;
            }
          });
          state.boxLabels = data.parcelTypes?.filter((item) => {
            if (item.type === "Box") {
              return item;
            }
          });
        })
        .catch((error) => Notify.apiError(error))
        .finally(() => {
          state.submitButtonLoading = false;
        });
    };

    getParcelLabels();

    const generateLabel = () => {
      let requestData = state.pickedParcels.map((item) => {
        return item.value;
      });

      state.submitButtonLoading = true;

      ApiService.post(`shipping-label/${props.id}`, {
        parcels_type: requestData,
      })
        .then(({ data }) => {
          if (data.type === "success") {
            ElMessage.success("Tracking generated");
            modal.value?.hide();
            emit("updated");
          }
        })
        .catch((error) => Notify.apiError(error))
        .finally(() => {
          state.submitButtonLoading = false;
        });
    };

    const deleteParcel = (id: number) => {
      let index = state.pickedParcels.findIndex((item) => item.id === id);
      state.pickedParcels.splice(index, 1);
    };

    const addParcelByBtn = (id: number, name: string) => {
      state.parcelId++;
      state.pickedParcels.push({
        id: state.parcelId,
        value: id,
        name: name,
      });
    };

    return {
      props,
      state,
      deleteParcel,
      generateLabel,
      addParcelByBtn,
      modal,
      getParcelLabels,
    };
  },
});
