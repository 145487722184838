<template>
  <div class="table-responsive">
    <el-table
        :data="items"
        class="table table-sm table-borderless"
        size="mini"
        :row-class-name="getRowClassName"
    >
      <el-table-column prop="id" label="ID" width="90" class-name="lh-1">
        <template #default="{ row }">
          <div class="lh-1">
            <router-link :to="{ name: 'orders-view', params: { id: row.id } }">
              {{ row.id }}
            </router-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="total_paid" label="Initial amount">
        <template #default="{ row }">
          {{ $filters.currencyEUR(row.initial_amount) }}
        </template>
      </el-table-column>
      <el-table-column label="State" width="140">
        <template #default="{ row }">
          <order-status-badge :status="row.status"/>
        </template>
      </el-table-column>
      <el-table-column label="Marks" width="140">
        <template #default="{ row }">
          <span
              v-for="mark in row.marks"
              :key="mark.id"
              class="me-2"
          >
            <order-mark-badge
                :mark="mark.order_mark"
                :is-deletable="false"
            />
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="payment.provider" label="Payment"/>
      <el-table-column
          prop="created_at"
          label="Date"
          class-name="lh-1"
          width="150"
      >
      </el-table-column>
      <el-table-column prop="email_match" class-name="text-center" width="50">
        <template #header>
          <i class="fas fa-at"></i>
        </template>
        <template #default="{ row }">
          <span
              class="bi"
              :class="{
              'bi-check-circle-fill': row.email_match === 1,
              'bi-slash-circle': row.email_match === 0,
              'text-success': row.email_match === 1,
              'text-danger': row.email_match === 0,
            }"
              :title="row.customer.email"
          ></span>
        </template>
      </el-table-column>
      <el-table-column prop="address_match" class-name="text-center" width="50">
        <template #header>
          <i class="fas fa-home"></i>
        </template>
        <template #default="{ row }">
          <span
              class="bi"
              :class="{
              'bi-check-circle-fill': row.address_match === 1,
              'bi-slash-circle': row.address_match === 0,
              'text-success': row.address_match === 1,
              'text-danger': row.address_match === 0,
            }"
              :title="
              row.address.country.name +
              '\n' +
              (row.address.state.name ? row.address.state.name + '\n' : '') +
              row.address.city +
              '\n' +
              row.address.address1 +
              '\n' +
              (row.address.address2 != null ? row.address.address2 : '')
            "
          ></span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {PreviousOrder} from "@/modules/common/interfaces/PreviousOrdersResponse.interface";
import OrderStatusBadge from "@/modules/orders/widgets/OrderStatusBadge.vue";
import OrderMarkBadge from "@/modules/orders/widgets/OrderMarkBadge.vue";

export default defineComponent({
  name: "PreviousOrdersTable",
  components: {OrderMarkBadge, OrderStatusBadge},
  props: {
    items: {
      type: Object as PropType<PreviousOrder[]>,
      required: true,
    },
    currentId: {
      type: Number,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const getRowClassName = ({row}) => {
      if (props.currentId != null && row.id === props.currentId) {
        return "bg-secondary";
      }
      return "";
    };

    return {getRowClassName};
  },
});
</script>

<style></style>
