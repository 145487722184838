<template>
  <modal-component
    id="order-tracking-validate-modal"
    ref="modal"
    size="medium"
    @hide="() => $emit('hide')"
  >
    <template #title>Tracking validation</template>
    <template #default>
      <div class="row">
        <h4>Are you sure you fixed?</h4>
      </div>
      <div class="row">
        <form-submit-button
          :loading="state.submitButtonLoading"
          @click="() => validateTracking()"
        >
          Confirm
        </form-submit-button>
      </div>
    </template>
  </modal-component>
</template>

<script lang="ts">
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import { defineComponent, reactive, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus/es";
import Notify from "@/modules/common/helpers/Notify";

interface ComponentData {
  submitButtonLoading: boolean;
}

export default defineComponent({
  name: "OrderTrackingValidateModal",
  components: { ModalComponent, FormSubmitButton },
  props: {
    trackingValidatorId: {
      required: true,
      type: Number,
    },
  },

  emits: ["done", "hide"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
    });
    const modal = ref<typeof ModalComponent | null>(null);

    const validateTracking = () => {
      state.submitButtonLoading = true;
      ApiService.post(`/order-tracking/${props.trackingValidatorId}/validate/`)
        .then((data) => {
          if (!data.data.isValid) {
            ElMessage.error("Validation error.");
          } else {
            ElMessage.success("Successfully validated.");
          }
          state.submitButtonLoading = false;
          modal.value?.hide();
          emit("done");
        })
        .catch((error) => {
          Notify.apiError(error);
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    };

    return {
      state,
      modal,
      props,
      validateTracking,
    };
  },
});
</script>

<style scoped></style>
