<template>
  <h6>Extras:</h6>
  <table class="table table-bordered">
    <tr v-for="extra in extras" :key="extra.id">
      <td>{{ extra.name }}:</td>
      <td>
        {{ $filters.currencyEUR(extra.amount) }}
      </td>
      <td v-if="$filters.checkUserPermission('delete_order_extras')">
        <button
          v-if="extra.type !== 'ddp'"
          type="button"
          class="btn btn-sm delete_extra"
          data-bs-toggle="modal"
          data-bs-target="#delete_order_extra_modal"
          @click="() => deleteOrderExtra(extra.id)"
        >
          <i class="fas text-danger fa-times"></i>
        </button>
      </td>
    </tr>
  </table>

  <order-delete-extras-modal
    v-if="state.isDeleteOrderExtrasModal"
    :order-extras-id="state.deletableOrderExtra"
    :order-id="id"
    @hide="
      () => {
        state.isDeleteOrderExtrasModal = false;
      }
    "
    @deleted="() => done()"
  />
</template>

<script lang="ts">
import { defineComponent, PropType, reactive } from "vue";
import OrderDeleteExtrasModal from "@/modules/orders/widgets/OrderExtras/OrderDeleteExtrasModal.vue";
import { OrderExtras } from "@/modules/orders/interfaces/OrderItemsResponse.interface";

interface ComponentData {
  isDeleteOrderExtrasModal: boolean;
  deletableOrderExtra?: number;
}

export default defineComponent({
  name: "OrderExtrasWidget",
  components: { OrderDeleteExtrasModal },

  props: {
    id: {
      type: Number,
      required: true,
    },
    extras: {
      type: Object as PropType<OrderExtras[]>,
      required: true,
    },
  },

  emits: ["updated"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      deletableOrderExtra: undefined,
      isDeleteOrderExtrasModal: false,
    });

    const deleteOrderExtra = (orderExtraId: number) => {
      state.deletableOrderExtra = orderExtraId;
      state.isDeleteOrderExtrasModal = true;
    };

    function done() {
      state.isDeleteOrderExtrasModal = false;
      emit("updated");
    }

    return {
      state,
      props,
      done,
      deleteOrderExtra,
    };
  },
});
</script>

<style scoped>
.delete_extra {
  padding: 0.25rem 0 !important;
}
</style>
