<template>
  <card-component>
    <template #header> Order Information</template>
    <template v-if="layout !== 'packing'" #toolbar>
      <button
        v-if="$filters.checkUserPermission('generate_tracking')"
        type="button"
        class="btn btn-sm btn-secondary mx-1"
        @click="
          () => {
            state.isGenerateLabelModal = true;
          }
        "
      >
        <i class="fas fa-paper-plane"></i>Generate tracking
      </button>
      <button
        type="button"
        class="btn btn-sm btn-secondary mx-1"
        :disabled="!state.isOrderHasTrackingNumber"
      >
        <i class="fas fa-file"></i>View label
      </button>
      <button
        type="button"
        class="btn btn-sm btn-secondary mx-1"
        :disabled="!state.isOrderHasTrackingNumber"
      >
        <i class="fas fa-globe-europe"></i>Print labels
      </button>
      <button
        v-if="$filters.checkUserPermission('print_return')"
        type="button"
        class="btn btn-sm btn-secondary mx-1"
      >
        <i class="fas fa-sign-in-alt"></i>Print return
      </button>
      <button
        v-if="$filters.checkUserPermission('print_box')"
        type="button"
        class="btn btn-sm btn-secondary mx-1"
      >
        <i class="fas fa-barcode"></i>Print box
      </button>

      <OrderViewInvoiceButton :id="props.id" />

      <OrderReturnInstructionsButton :id="props.id" />
    </template>
    <template #default>
      <div v-if="state.loading" class="text-center">
        <loading-indicator />
      </div>
      <request-failed-error
        v-if="state.error"
        :exception="state.error"
        @refresh="() => loadData()"
      />
      <div class="info_table">
        <order-information-table
          v-if="state.order != null"
          :id="id"
          :order="state.order"
          :order-tracking="props.orderTracking"
          @updated="() => loadData()"
          @reload-order="() => emit('reloadOrder')"
        />
      </div>
    </template>
  </card-component>

  <order-generate-label-modal
    v-if="state.isGenerateLabelModal"
    :id="id"
    @hide="
      () => {
        state.isGenerateLabelModal = false;
      }
    "
    @updated="
      () => {
        state.isGenerateLabelModal = false;
      }
    "
  />
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  PropType,
  reactive,
  toRefs,
  watch,
} from "vue";
import CardComponent from "@/components/card/CardComponent.vue";
import LoadingIndicator from "@/components/spinners/LoadingIndicator.vue";
import RequestFailedError from "@/components/errors/RequestFailedError.vue";
import ApiService from "@/core/services/ApiService";
import * as OrderDetails from "@/modules/orders/interfaces/OrderDetails.interface";
import OrderInformationTable from "@/modules/orders/widgets/OrderInformation/OrderInformationTable.vue";
import OrderGenerateLabelModal from "@/modules/orders/widgets/OrderManagement/OrderGenerateLabelModal.vue";
import OrderReturnInstructionsButton from "@/modules/orders/widgets/OrderManagement/OrderReturnInstructionsButton.vue";
import { TrackingHistory } from "@/modules/orders/interfaces/OrderTrackingHistory.interface";
import OrderViewInvoiceButton from "@/modules/orders/widgets/OrderManagement/OrderViewInvoiceButton.vue";

interface ComponentData {
  loading: boolean;
  error?: unknown;
  order?: OrderDetails.Order;
  isOrderHasTrackingNumber: boolean;
  isGenerateLabelModal: boolean;
}

export default defineComponent({
  name: "OrderInformationWidget",
  components: {
    OrderViewInvoiceButton,
    OrderGenerateLabelModal,
    OrderInformationTable,
    RequestFailedError,
    LoadingIndicator,
    CardComponent,
    OrderReturnInstructionsButton,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    orderTracking: {
      type: Object as PropType<TrackingHistory[]>,
      required: true,
    },
    role: {
      type: String as PropType<"basic" | "tracking" | "packing">,
      default: "basic",
    },
  },
  emits: ["loaded", "reloadOrder"],
  setup(props, { expose, emit }) {
    const { id } = toRefs(props);

    const state = reactive<ComponentData>({
      loading: true,
      error: undefined,
      order: undefined,
      isOrderHasTrackingNumber: false,
      isGenerateLabelModal: false,
    });

    const resetState = (): void => {
      state.order = undefined;
      state.loading = true;
      state.error = undefined;
    };

    const loadData = () => {
      resetState();
      ApiService.get(`order/${props.id}/details`)
        .then(({ data }: { data: OrderDetails.Order }) => {
          if (data.tracking?.[0] !== undefined) {
            state.isOrderHasTrackingNumber = true;
          }
          state.order = data;
          emit("loaded", data);
        })
        .catch((error) => {
          state.error = error;
          emit("loaded", null);
        })
        .finally(() => {
          state.loading = false;
        });
    };

    watch(id, loadData);

    expose({
      loadData,
    });

    const layout = localStorage.layout;

    onMounted(() => {
      loadData();
    });

    return {
      props,
      emit,
      state,
      loadData,
      layout,
    };
  },
});
</script>

<style>
</style>
