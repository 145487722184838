
import { defineComponent, onMounted, PropType, reactive, ref } from "vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import { useForm } from "vee-validate";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import { OrderItem } from "@/modules/orders/interfaces/OrderItemsResponse.interface";
import { ElMessage } from "element-plus";

interface resultItems {
  reason_id: number;
  id: number;
}

interface ComponentData {
  resultItems: resultItems[];
  itemDeleteReasons:
    | {
        id: number;
        descriptions: string;
      }[]
    | null;
  submitButtonLoading: boolean;
}

export default defineComponent({
  name: "OrderItemsDeleteModal",
  components: {
    FormSubmitButton,
    ModalComponent,
  },
  props: {
    items: {
      required: true,
      type: Object as PropType<OrderItem[]>,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  emits: ["itemDeleted", "hide"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      resultItems: [],
      itemDeleteReasons: null,
      submitButtonLoading: false,
    });
    const modal = ref<typeof ModalComponent | null>(null);

    const setItems = () => {
      for (const i in props.items) {
        state.resultItems[i] = {
          id: props.items[i].id,
          reason_id: 0,
        };
      }
    };
    setItems();

    const updateReasons = (event) => {
      for (const item of state.resultItems) {
        if (item.reason_id === 0) {
          item.reason_id = event.target.value;
        }
      }
    };

    const { handleSubmit } = useForm();

    const deleteItems = handleSubmit(() => {
      for (let item of state.resultItems) {
        if (item.reason_id <= 0) {
          Notify.apiError({
            name: "notSelectedDeletingReason",
            message: "Please select deleting reason.",
          });
          return false;
        }
      }
      ApiService.post("order/" + props.id.toString() + "/items/delete", {
        items: state.resultItems,
      })
        .then(() => {
          state.submitButtonLoading = false;
          ElMessage.success("Items deleted.");
          modal.value?.hide();
          emit("itemDeleted");
        })
        .catch((error) => {
          Notify.apiError(error);
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    });

    const getItemDeleteReasons = () => {
      ApiService.get(`items/reasons`)
        .then(({ data }: { data }) => {
          state.itemDeleteReasons = data.reasons;
        })
        .catch((error) => {
          Notify.apiError(error);
        });
    };

    onMounted(() => {
      setItems();
      getItemDeleteReasons();
    });
    return {
      state,
      getItemDeleteReasons,
      updateReasons,
      deleteItems,
      modal,
    };
  },
});
