<template>
  <button
    type="button"
    class="btn btn-sm btn-secondary"
    @click="state.isCancelInvoiceModal = true"
  >
    Cancel invoice
  </button>

  <order-cancel-invoice-modal
    v-if="state.isCancelInvoiceModal === true"
    :id="id"
    @hide="state.isCancelInvoiceModal = false"
    @done="done()"
  />
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import OrderCancelInvoiceModal from "@/modules/orders/widgets/OrderManagement/OrderCancelInvoiceModal.vue";

interface ComponentData {
  isCancelInvoiceModal: boolean;
}

export default defineComponent({
  name: "OrderCancelInvoiceButton",
  components: { OrderCancelInvoiceModal },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  emits: ["updated"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      isCancelInvoiceModal: false,
    });

    function done() {
      state.isCancelInvoiceModal = false;
      emit("updated");
    }

    return {
      state,
      props,
      done,
    };
  },
});
</script>

<style scoped></style>
