<template>
  <div
    v-if="
      props.balance.refundable > 1 &&
      props.balance.refundable !== props.balance.pending_refunds
    "
    class="alert alert-danger"
  >
    You have difference of <strong>€ {{ props.balance.refundable }}</strong
    >, you can refund or send a voucher

    <button
      v-if="props.balance.refundable_by_money >= 1"
      class="btn btn-primary m-1"
      @click="state.isRefundWithMoney = true"
    >
      Request Money Refund
    </button>

    <button
      v-if="props.balance.pending_refunds === 0"
      class="btn btn-primary m-1"
      @click="state.isRefundByVoucher = true"
    >
      Refund by Voucher <strong>(€ {{ props.balance.refundable }})</strong>
    </button>

    <button
      v-if="props.balance.pending_refunds === 0"
      class="btn btn-primary m-1"
      @click="
        () => {
          state.isRefundByVoucher = true;
          state.isRefundWithPromotion = true;
        }
      "
    >
      Refund by Voucher + 20% Promotion
      <strong>(€ {{ (props.balance.refundable * 1.2).toFixed(2) }})</strong>
    </button>
  </div>

  <OrderRefundByVoucherModal
    v-if="state.isRefundByVoucher === true"
    :id="props.id"
    :is-promotion="state.isRefundWithPromotion"
    @hide="
      () => {
        state.isRefundByVoucher = false;
        state.isRefundWithPromotion = false;
      }
    "
    @done="() => updateOrder()"
  />

  <OrderRefundWithMoneyModal
    v-if="state.isRefundWithMoney === true"
    :id="props.id"
    @hide="() => (state.isRefundWithMoney = false)"
    @done="() => updateOrder()"
  />
</template>

<script lang="ts">
import { defineComponent, PropType, reactive } from "vue";
import OrderRefundWithMoneyModal from "@/modules/orders/widgets/OrderRefunds/OrderRefundWithMoneyModal.vue";
import OrderRefundByVoucherModal from "@/modules/orders/widgets/OrderRefunds/OrderRefundByVoucherModal.vue";
import { OrderPaymentsResponse } from "@/modules/orders/interfaces/OrderPaymentsResponse.interface";

interface OrderBalance {
  current: number;
  refundable: number;
  refundable_by_money: number;
}

interface ComponentData {
  isRefundWithMoney: boolean;
  isRefundByVoucher: boolean;
  isRefundWithPromotion: boolean;
}

export default defineComponent({
  name: "OrderRefundsButtonsAlert",
  components: {
    OrderRefundWithMoneyModal,
    OrderRefundByVoucherModal,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    balance: {
      type: Object as PropType<OrderBalance>,
      default: null,
    },
    payments: {
      type: Object as PropType<OrderPaymentsResponse>,
      required: true,
    },
  },

  emits: ["updated"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      isRefundWithMoney: false,
      isRefundByVoucher: false,
      isRefundWithPromotion: false,
    });

    const updateOrder = () => {
      emit("updated");
    };

    return {
      state,
      props,
      emit,
      updateOrder,
    };
  },
});
</script>

<style scoped></style>
