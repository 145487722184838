
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  watch,
} from "vue";
import ApiService from "@/core/services/ApiService";
import CardComponent from "@/components/card/CardComponent.vue";
import LoadingIndicator from "@/components/spinners/LoadingIndicator.vue";
import RequestFailedError from "@/components/errors/RequestFailedError.vue";
import {
  OrderItemsResponse,
  OrderItem,
} from "@/modules/orders/interfaces/OrderItemsResponse.interface";
import OrderItemsTable from "@/modules/orders/widgets/OrderItems/OrderItemsTable.vue";
import OrderItemsTotals from "@/modules/orders/widgets/OrderItems/OrderItemsTotals.vue";
import Notify from "@/modules/common/helpers/Notify";
import { useStore } from "vuex";
import OrderAddItemsButton from "@/modules/orders/widgets/OrderItems/OrderAddItemsButton.vue";
import OrderCancelInvoiceButton from "@/modules/orders/widgets/OrderManagement/OrderCancelInvoiceButton.vue";
import OrderAddExtrasButton from "@/modules/orders/widgets/OrderExtras/OrderAddExtrasButton.vue";
import OrderExtrasWidget from "@/modules/orders/widgets/OrderExtras/OrderExtrasWidget.vue";
import OrderFamilyOrders from "@/modules/orders/widgets/OrderInformation/OrderFamilyOrders.vue";
import OrderItemsPackingTable from "@/modules/orders/widgets/OrderItems/OrderItemsPackingTable.vue";

interface ComponentData {
  loading: boolean;
  error?: unknown;
  response?: OrderItemsResponse;
  isOrderCompleted: boolean;
  itemsWithoutDeleted: OrderItem[] | null;
  isDeleteOrderExtrasModal: boolean;
  deletableOrderExtra?: number;
}

export default defineComponent({
  name: "OrderItemsWidget",
  components: {
    OrderItemsPackingTable,
    OrderFamilyOrders,
    OrderExtrasWidget,
    OrderAddExtrasButton,
    OrderCancelInvoiceButton,
    OrderAddItemsButton,
    OrderItemsTotals,
    OrderItemsTable,
    RequestFailedError,
    LoadingIndicator,
    CardComponent,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  emits: ["updated"],
  setup(props, { emit }) {
    const { id } = toRefs(props);

    const state = reactive<ComponentData>({
      loading: false,
      error: undefined,
      response: undefined,
      isOrderCompleted: false,
      itemsWithoutDeleted: [],
      deletableOrderExtra: undefined,
      isDeleteOrderExtrasModal: false,
    });

    const roles = computed(() => {
      return useStore().getters.currentUser.roles;
    });

    const resetState = (): void => {
      state.response = undefined;
      state.loading = true;
      state.error = undefined;
    };

    const deleteOrderExtra = (orderExtraId: number) => {
      state.deletableOrderExtra = orderExtraId;
      state.isDeleteOrderExtrasModal = true;
    };

    const loadOrderItemsData = () => {
      emit("updated");
      resetState();
      ApiService.get(`order/${props.id}/items`)
        .then(({ data }: { data: OrderItemsResponse }) => {
          state.response = data;
          if (data.items != null) {
            state.itemsWithoutDeleted = [];
            for (let item of data.items) {
              if (item.deleted_at == null) {
                state.itemsWithoutDeleted.push(item);
              }
            }
          }
        })
        .catch((error) => {
          state.error = error;
        })
        .finally(() => {
          state.loading = false;
        });
    };

    const isOrderCompleted = () => {
      ApiService.get(`order/${props.id}/is-completed`)
        .then(({ data }) => {
          state.isOrderCompleted = data.isOrderCompleted;
        })
        .catch((error) => {
          Notify.apiError(error);
        });
    };

    onMounted(() => {
      loadOrderItemsData();
      isOrderCompleted();
    });

    watch(id, loadOrderItemsData);

    const layout = localStorage.layout;

    return {
      state,
      props,
      roles,
      loadOrderItemsData,
      deleteOrderExtra,
      layout,
    };
  },
});
