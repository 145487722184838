
import { defineComponent, PropType } from "vue";
import { OrderStatus } from "@/modules/orders/interfaces/OrderDetails.interface";

export default defineComponent({
  name: "OrderStatusBadge",
  props: {
    status: {
      type: Object as PropType<OrderStatus>,
      required: true,
    },
  },
});
