
import { defineComponent, PropType, reactive, ref } from "vue";
import OrderItemsWidget from "@/modules/orders/widgets/OrderItems/OrderItemsWidget.vue";
import OrderMessagesWidget from "@/modules/orders/widgets/OrderMessages/OrderMessagesWidget.vue";
import OrderInformationWidget from "@/modules/orders/widgets/OrderInformation/OrderInformationWidget.vue";
import { OrderTrackingHistory } from "@/modules/orders/interfaces/OrderTrackingHistory.interface";
import { OrderBalance } from "@/modules/orders/interfaces/OrderBalance.interface";
import { OrderPaymentsResponse } from "@/modules/orders/interfaces/OrderPaymentsResponse.interface";
import OrderTrackingWidget from "@/modules/orders/widgets/OrderTracking/OrderTrackingWidget.vue";
import OrderStatusHistoryWidget from "@/modules/orders/widgets/OrderStatusHistory/OrderStatusHistoryWidget.vue";
import OrderMarksWidget from "@/modules/orders/widgets/OrderMarks/OrderMarksWidget.vue";

interface ComponentData {
  orderBalance?: OrderBalance | null;
  orderPayments?: OrderPaymentsResponse | null;
}

export default defineComponent({
  name: "OrderTrackingLayout",
  components: {
    OrderMarksWidget,
    OrderStatusHistoryWidget,
    OrderTrackingWidget,
    OrderItemsWidget,
    OrderMessagesWidget,
    OrderInformationWidget,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    orderTracking: {
      type: Object as PropType<OrderTrackingHistory[]>,
      required: true,
    },
  },
  emits: ["updated"],

  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      orderBalance: null,
      orderPayments: null,
    });
    const messages = ref<typeof OrderMessagesWidget | null>(null);
    const orderInformationWidget = ref<typeof OrderInformationWidget | null>(
      null
    );

    const refreshOrder = () => {
      emit("updated");

      messages.value?.loadData();
      orderInformationWidget.value?.loadData();
    };
    return {
      props,
      emit,
      state,
      refreshOrder,
    };
  },
});
