
import { defineComponent, reactive, ref } from "vue";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus";
import Notify from "@/modules/common/helpers/Notify";

interface ComponentData {
  submitButtonLoading: boolean;
}

export default defineComponent({
  name: "OrderRefundWithMoneyModal",

  components: {
    FormSubmitButton,
    ModalComponent,
  },
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  emits: ["done", "hide"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
    });
    const modal = ref<typeof ModalComponent | null>(null);

    const sendRefund = () => {
      state.submitButtonLoading = true;
      ApiService.post(`order/` + props.id + `/refund-with-money`)
        .then((data) => {
          console.log(data);
          state.submitButtonLoading = false;
          ElMessage.success("Order refund with money request sent.");
          modal.value?.hide();
          emit("done");
        })
        .catch((error) => {
          Notify.apiError(error);
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    };

    return {
      state,
      modal,
      props,
      sendRefund,
    };
  },
});
