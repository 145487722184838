
import { defineComponent, PropType, reactive } from "vue";
import OrderReshipLostParcelModal from "@/modules/orders/widgets/OrderManagement/OrderReshipLostParcelModal.vue";
import { OrderItem } from "@/modules/orders/interfaces/OrderItemsResponse.interface";

interface ComponentData {
  isReshipLostParcelModal: boolean;
}

export default defineComponent({
  name: "OrderReshipLostParcelButton",
  components: { OrderReshipLostParcelModal },
  props: {
    items: {
      required: true,
      type: Object as PropType<OrderItem[]>,
    },
  },

  emits: ["updated"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      isReshipLostParcelModal: false,
    });

    function done() {
      state.isReshipLostParcelModal = false;
      emit("updated");
    }

    return {
      state,
      props,
      done,
    };
  },
});
