
import { defineComponent, onMounted, PropType, reactive } from "vue";
import { OrderItem } from "@/modules/orders/interfaces/OrderItemsResponse.interface";
import Badge from "@/components/Badge.vue";
import OrderItemsDeleteModal from "@/modules/orders/widgets/OrderItems/OrderItemsDeleteModal.vue";
import OrderReshipWrongItemsModal from "@/modules/orders/widgets/OrderManagement/OrderReshipWrongItemsModal.vue";
import OrderSplitButton from "@/modules/orders/widgets/OrderManagement/OrderSplitButton.vue";
import OrderReshipLostParcelButton from "@/modules/orders/widgets/OrderManagement/OrderReshipLostParcelButton.vue";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";

interface ComponentData {
  selected: number[];
  splitThreshold: number | null;
  isCheckAll: boolean;
  bulkAction: string | null;
  pickedItems: OrderItem[] | null;
  deletingItems: OrderItem[] | null;
  isDeleteModal: boolean;
  isReshipWrongItemsModal: boolean;
  isReshipLostParcelModal: boolean;
  itemsWithoutDeleted: OrderItem[] | [];
  isBulkActionsEnabled: boolean;
}

export default defineComponent({
  name: "OrderItemsTable",
  components: {
    OrderReshipWrongItemsModal,
    OrderItemsDeleteModal,
    Badge,
    OrderReshipLostParcelButton,
    OrderSplitButton,
  },
  props: {
    items: {
      required: true,
      type: Object as PropType<OrderItem[]>,
    },
    id: {
      type: Number,
      required: true,
    },
  },

  emits: ["itemsUpdated"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      selected: [],
      splitThreshold: null,
      isCheckAll: false,
      bulkAction: "default",
      pickedItems: [],
      deletingItems: [],
      isDeleteModal: false,
      isReshipWrongItemsModal: false,
      isReshipLostParcelModal: false,
      itemsWithoutDeleted: [],
      isBulkActionsEnabled: false,
    });

    const buildOversightUrl = (item: OrderItem): string => {
      return `https://oversight.bambiniservices.com/product/${item.product_id}`;
    };

    const hasWarehouseData = (item: OrderItem): boolean => {
      return item.warehouse != null;
    };

    const getItemLocation = (item: OrderItem) => {
      if (item.warehouse != null) {
        return item.warehouse.location;
      }
      return "";
    };

    const getItemBarcode = (item: OrderItem) => {
      if (item.warehouse != null) {
        return item.warehouse.barcode;
      }
      return "";
    };

    const applyBulkAction = () => {
      if (state.bulkAction === "delete") deleteItems(state.selected);
      if (state.bulkAction === "reship") reshipItems();
      return false;
    };

    const hideModal = (): void => {
      state.isDeleteModal = false;
      state.isReshipWrongItemsModal = false;
      state.deletingItems = [];
    };

    const deleteItems = (picked): void => {
      state.isDeleteModal = true;
      state.deletingItems = [];

      for (const item of state.itemsWithoutDeleted) {
        if (typeof picked == "number" && item.id == picked) {
          state.deletingItems[state.deletingItems?.length] = item;
        }

        if (typeof picked == "object" && picked.includes(item.id)) {
          state.deletingItems[state.deletingItems?.length] = item;
        }
      }
    };

    const reshipItems = (): void => {
      state.isReshipWrongItemsModal = true;
    };

    const checkAll = (): void => {
      state.isCheckAll = !state.isCheckAll;
      state.isBulkActionsEnabled = state.isCheckAll;
      state.selected = [];
      if (state.isCheckAll) {
        for (const item of state.itemsWithoutDeleted) {
          state.selected.push(item.id);
        }
      }
    };

    const getCountryThreshold = () => {
      ApiService.post(`order/${props.id}/country/threshold`)
        .then(({ data }) => {
          state.splitThreshold = data["threshold"].split_threshold;
        })
        .catch((error) => {
          Notify.apiError(error);
        });
    };

    const itemsUpdated = () => {
      emit("itemsUpdated");
    };

    const updateCheckAll = () => {
      state.isBulkActionsEnabled = state.selected.length > 0;
      state.isCheckAll =
        state.selected.length == state.itemsWithoutDeleted.length;
    };

    onMounted(() => {
      getCountryThreshold();
      if (props.items != undefined) {
        state.itemsWithoutDeleted = [];
        for (let item of props.items) {
          if (item.deleted_at == null) {
            state.itemsWithoutDeleted[state.itemsWithoutDeleted.length] = item;
          }
        }
      }
    });

    const layout = localStorage.layout;

    return {
      state,
      buildOversightUrl,
      hasWarehouseData,
      getItemLocation,
      getItemBarcode,
      checkAll,
      updateCheckAll,
      applyBulkAction,
      deleteItems,
      reshipItems,
      itemsUpdated,
      hideModal,
      layout,
    };
  },
});
