
import { defineComponent, onMounted, reactive, toRefs, watch } from "vue";
import CardComponent from "@/components/card/CardComponent.vue";
import LoadingIndicator from "@/components/spinners/LoadingIndicator.vue";
import RequestFailedError from "@/components/errors/RequestFailedError.vue";
import { PreviousOrdersResponse } from "@/modules/common/interfaces/PreviousOrdersResponse.interface";
import ApiService from "@/core/services/ApiService";
import PreviousOrdersTable from "@/modules/common/widgets/PreviousOrders/PreviousOrdersTable.vue";

interface ComponentData {
  loading: boolean;
  error?: unknown;
  page: number;
  response?: PreviousOrdersResponse;
}

export default defineComponent({
  name: "PreviousOrdersWidget",
  components: {
    PreviousOrdersTable,
    RequestFailedError,
    LoadingIndicator,
    CardComponent,
  },
  props: {
    orderId: {
      type: Number,
      required: false,
      default: null,
    },
    customerId: {
      type: Number,
      required: false,
      default: null,
    },
    headerName: {
      type: String,
      required: false,
      default: "Previous Orders",
    },
  },
  setup(props) {
    const { customerId, orderId } = toRefs(props);

    const state = reactive<ComponentData>({
      loading: false,
      error: undefined,
      page: 1,
      response: undefined,
    });

    const resetState = (): void => {
      state.response = undefined;
      state.loading = true;
      state.error = undefined;
    };

    const loadData = (): void => {
      resetState();
      ApiService.get(`widgets/orders/previous`, {
        params: {
          page: state.page,
          order_id: props.orderId,
          customer_id: props.customerId,
        },
      })
        .then(({ data }: { data: PreviousOrdersResponse }) => {
          state.response = data;
        })
        .catch((error) => {
          state.error = error;
        })
        .finally(() => {
          state.loading = false;
        });
    };

    const pageChanged = (page) => {
      state.page = page;
      loadData();
    };

    onMounted(loadData);

    watch(customerId, loadData);
    watch(orderId, loadData);

    return { state, pageChanged, loadData };
  },
});
