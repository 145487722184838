
import { computed, defineComponent, PropType, reactive } from "vue";
import { MessagesEntity } from "../../interfaces/OrderMessagesResponse.interface";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import OrderDeleteMessagesModal from "@/modules/orders/widgets/OrderMessages/OrderDeleteMessagesModal.vue";

interface ComponentData {
  isDeleteOrderMessageModal: boolean;
  downloading: boolean;
}

export default defineComponent({
  name: "OrderMessage",
  components: {
    OrderDeleteMessagesModal,
  },
  props: {
    message: {
      type: Object as PropType<MessagesEntity>,
      required: true,
    },
  },
  emits: ["deleted", "hide"],

  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      downloading: false,
      isDeleteOrderMessageModal: false,
    });

    const messageDeleted = () => {
      state.isDeleteOrderMessageModal = false;
      emit("deleted");
    };

    const deleteOrderMessage = () => {
      state.isDeleteOrderMessageModal = true;
    };

    const downloadFile = (): void => {
      state.downloading = true;
      ApiService.get(`order/messages/file/${props.message.id}`, {
        responseType: "blob",
      })
        .then(({ data }) => {
          if (props.message.attachment_name != null) {
            const fileURL = window.URL.createObjectURL(new Blob([data]));
            const fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", props.message.attachment_name);
            document.body.appendChild(fileLink);

            fileLink.click();
          }
        })
        .catch((error) => {
          Swal.fire({
            text: error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        })
        .finally(() => {
          state.downloading = false;
        });
    };

    const userName = computed((): string => {
      if (props.message.user != null) {
        return props.message.user.name;
      }
      return "Unknown #" + props.message.user_id;
    });

    return {
      state,
      downloadFile,
      userName,
      deleteOrderMessage,
      messageDeleted,
    };
  },
});
