
import { defineComponent, PropType, reactive } from "vue";
import OrderDeleteExtrasModal from "@/modules/orders/widgets/OrderExtras/OrderDeleteExtrasModal.vue";
import { OrderExtras } from "@/modules/orders/interfaces/OrderItemsResponse.interface";

interface ComponentData {
  isDeleteOrderExtrasModal: boolean;
  deletableOrderExtra?: number;
}

export default defineComponent({
  name: "OrderExtrasWidget",
  components: { OrderDeleteExtrasModal },

  props: {
    id: {
      type: Number,
      required: true,
    },
    extras: {
      type: Object as PropType<OrderExtras[]>,
      required: true,
    },
  },

  emits: ["updated"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      deletableOrderExtra: undefined,
      isDeleteOrderExtrasModal: false,
    });

    const deleteOrderExtra = (orderExtraId: number) => {
      state.deletableOrderExtra = orderExtraId;
      state.isDeleteOrderExtrasModal = true;
    };

    function done() {
      state.isDeleteOrderExtrasModal = false;
      emit("updated");
    }

    return {
      state,
      props,
      done,
      deleteOrderExtra,
    };
  },
});
