<template>
  <span :class="badgeClass"><slot></slot></span>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "Badge",
  props: {
    type: {
      default: "default",
      required: false,
      type: String as PropType<
        | "white"
        | "primary"
        | "light"
        | "secondary"
        | "success"
        | "info"
        | "warning"
        | "danger"
        | "dark"
        | "default"
      >,
    },
  },
  computed: {
    badgeClass(): string {
      const classes = ["badge"];
      classes.push("badge-" + (this.type || "default"));
      return classes.join(" ");
    },
  },
});
</script>

<style scoped></style>
