<template>
  <div class="table-responsive">
    <table
      class="table mb-0"
      :class="{
        info_table_packing: layout === 'packing',
      }"
    >
      <thead>
        <tr class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
          <th>ID</th>
          <th class="min-w-150px">
            Customer
            <button
              v-if="layout !== 'packing'"
              type="button"
              class="btn btn-sm edit_button"
              :disabled="state.isOrderCompleted"
              @click="state.isEditCustomer = true"
            >
              <i class="fas fa-pen"></i>
            </button>
          </th>
          <th class="min-w-150px">
            Delivery Address
            <button
              v-if="layout !== 'packing'"
              type="button"
              class="btn btn-sm edit_button"
              :disabled="state.isOrderCompleted"
              @click="editAddress(props.order.address)"
            >
              <i class="fas fa-pen"></i>
            </button>
          </th>
          <th
            v-if="props.order.invoiceAddress"
            class="min-w-150px"
            style="line-height: 0"
          >
            Invoice Address
            <button
              type="button"
              class="btn btn-sm edit_button"
              :disabled="state.isOrderCompleted"
              @click="editAddress(props.order.invoiceAddress)"
            >
              <i class="fas fa-pen"></i>
            </button>
          </th>
          <th class="min-w-130px">Shipping</th>
          <th class="min-w-130px">DDP</th>
          <th v-if="layout !== 'packing'" class="min-w-130px">
            Tracking
            <button
              v-if="props.orderTracking.length > 0"
              type="button"
              class="btn btn-sm edit_button"
              @click="
                () => {
                  state.isSetTrackingNumberButton = true;
                }
              "
            >
              <i class="fas fa-pen"></i>
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ resolveField("id") }}</td>
          <td>
            <p
              v-if="
                props.order.customer.first_name ||
                props.order.customer.last_name
              "
            >
              <span v-if="isNotEmpty('customer.first_name')">
                {{ resolveField("customer.first_name") }}
              </span>
              &nbsp;
              <span v-if="isNotEmpty('customer.last_name')">
                {{ resolveField("customer.last_name") }}
              </span>
            </p>
            <p v-if="isNotEmpty('customer.email')">
              {{ resolveField("customer.email") }}
            </p>
            <div
              v-if="
                isNotEmpty('address.phone') &&
                isNotEmpty('invoiceAddress.phone') &&
                props.order.address.phone &&
                props.order.invoiceAddress.phone &&
                props.order.address.phone !== props.order.invoiceAddress.phone
              "
            >
              <p>
                <i class="fas fa-phone-alt"></i>
                {{ resolveField("address.phone") }} - delivery
              </p>
              <p>
                <i class="fas fa-phone-alt"></i>
                {{ resolveField("invoiceAddress.phone") }} - invoice
              </p>
            </div>
            <div v-else>
              <p v-if="isNotEmpty('address.phone')">
                <i class="fas fa-phone-alt"></i>
                {{ resolveField("address.phone") }}
              </p>
            </div>
          </td>

          <td>
            <p v-if="isNotEmpty('address.customer')">
              {{ resolveField("address.customer") }}
            </p>
            <p v-if="isNotEmpty('address.address1')">
              {{ resolveField("address.address1") }}
            </p>
            <p v-if="isNotEmpty('address.address2')">
              {{ resolveField("address.address2") }}
            </p>
            <p>
              <span v-if="isNotEmpty('address.zip')">
                {{ resolveField("address.zip") }},
              </span>
              {{ resolveField("address.city") }}
            </p>
            <p>
              <span v-if="isNotEmpty('address.state.name')">
                {{ resolveField("address.state.name") }},
              </span>
              {{ resolveField("address.country.name") }}
            </p>
            <p v-if="isNotEmpty('address.additional_information')">
              <i>{{ resolveField("address.additional_information") }}</i>
            </p>
            <p v-if="props.order.address.parent_address_id !== null">
              <span class="badge badge-pill badge-success"> Label </span>
            </p>
          </td>

          <td v-if="props.order.invoiceAddress">
            <p v-if="isNotEmpty('invoiceAddress.customer')">
              {{ resolveField("invoiceAddress.customer") }}
            </p>
            <p v-if="isNotEmpty('invoiceAddress.address1')">
              {{ resolveField("invoiceAddress.address1") }}
            </p>
            <p v-if="isNotEmpty('invoiceAddress.address2')">
              {{ resolveField("invoiceAddress.address2") }}
            </p>
            <p>
              <span v-if="isNotEmpty('invoiceAddress.zip')">
                {{ resolveField("invoiceAddress.zip") }},
              </span>
              {{ resolveField("invoiceAddress.city") }}
            </p>
            <p>
              <span v-if="isNotEmpty('invoiceAddress.state.name')">
                {{ resolveField("invoiceAddress.state.name") }},
              </span>
              {{ resolveField("invoiceAddress.country.name") }}
            </p>
            <p v-if="isNotEmpty('invoiceAddress.additional_information')">
              <i>{{ resolveField("invoiceAddress.additional_information") }}</i>
            </p>
            <p v-if="props.order.invoiceAddress.parent_address_id !== null">
              <span class="badge badge-pill badge-success"> Label </span>
            </p>
          </td>

          <td>{{ resolveField("delivery.name") }}</td>
          <td>{{ resolveField("address.is_ddp") ? "Yes" : "No" }}</td>
          <td v-if="layout !== 'packing'" class="min-w-150px">
            <order-set-tracking-number-button
              :id="id"
              :order-tracking="props.orderTracking"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <AddressUpdateModal
    v-if="state.isEditAddress === true"
    :address="state.editableAddress"
    @hide="() => (state.isEditAddress = false)"
    @updated="emit('updated')"
  />
  <CustomerUpdateModal
    v-if="state.isEditCustomer === true"
    :customer="state.customer"
    @updated="emit('updated')"
    @hide="() => (state.isEditCustomer = false)"
  />
  <OrderSetTrackingNumberModal
    v-if="state.isSetTrackingNumberButton === true"
    :id="id"
    :order-tracking="props.orderTracking"
    @hide="
      () => {
        state.isSetTrackingNumberButton = false;
      }
    "
    @updated="() => emit('reloadOrder')"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, reactive } from "vue";
import * as OrderDetails from "../../interfaces/OrderDetails.interface";
import get from "lodash/get";
import AddressUpdateModal from "@/modules/addresses/widgets/AddressUpdateModal.vue";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import CustomerUpdateModal from "@/modules/customers/widgets/CustomerUpdateModal.vue";
import { Customer } from "@/modules/customers/interfaces/CustomerResponse.interface";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import OrderSetTrackingNumberButton from "@/modules/orders/widgets/OrderTracking/OrderSetTrackingNumberButton.vue";
import { TrackingHistory } from "@/modules/orders/interfaces/OrderTrackingHistory.interface";
import OrderSetTrackingNumberModal from "@/modules/orders/widgets/OrderTracking/OrderSetTrackingNumberModal.vue";

interface ComponentData {
  isEditAddress: boolean;
  isOrderCompleted: boolean;
  editableAddress: OrderDetails.Order | null;
  isEditCustomer: boolean;
  isSetTrackingNumberButton: boolean;
  customer: Customer | null;
}

export default defineComponent({
  name: "OrderInformationTable",
  components: {
    OrderSetTrackingNumberButton,
    AddressUpdateModal,
    CustomerUpdateModal,
    OrderSetTrackingNumberModal,
  },
  props: {
    order: {
      type: Object as PropType<OrderDetails.Order>,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    orderTracking: {
      type: Object as PropType<TrackingHistory[]>,
      required: true,
    },
  },

  emits: ["updated", "reloadOrder"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      isEditAddress: false,
      isOrderCompleted: false,
      editableAddress: null,
      isEditCustomer: false,
      isSetTrackingNumberButton: false,
      customer: null,
    });
    const store = useStore();

    const isNotEmpty = (field: string): boolean => {
      return get(props.order, field) != null;
    };
    const resolveField = (field: string): string => {
      return get(props.order, field, "");
    };

    const editAddress = (address) => {
      state.editableAddress = address;
      state.isEditAddress = true;
    };

    const getCustomer = () => {
      ApiService.get(`customer/` + props.order.customer.id.toString())
        .then(({ data }) => {
          state.customer = data.customer;
        })
        .catch((error) => Notify.apiError(error))
        .finally();
    };

    const isOrderCompleted = () => {
      ApiService.get(`order/` + props.id.toString() + `/is-completed`)
        .then(({ data }) => {
          state.isOrderCompleted = data.isOrderCompleted;
        })
        .catch((error) => {
          Notify.apiError(error);
        });
    };

    onMounted(() => {
      isOrderCompleted();
      getCustomer();
      store.dispatch(Actions.SET_ORDER_INFO_ACTION, props.order);
    });

    const layout = localStorage.layout;

    return {
      emit,
      state,
      props,
      isNotEmpty,
      resolveField,
      editAddress,
      getCustomer,
      layout,
    };
  },
});
</script>

<style scoped>
p {
  margin-bottom: 0.4rem;
  line-height: 1.2;
}
.edit_button {
  padding: 1px 4px !important;
}

.info_table_packing th {
  font-size: 1.2rem;
}
.info_table_packing td {
  font-size: 1.3rem;
}
</style>
