<template>
  <card-component>
    <template #header>
      <span>Payments</span>
    </template>
    <template #default>
      <template v-if="payments != null">
        <div class="overflow-scroll">
          <table class="table table-sm table-borderless">
            <thead>
              <tr class="border-bottom text-muted">
                <th class="min-w-80px align-top">ID</th>
                <th class="min-w-150px align-top">Date</th>
                <th class="min-w-100px align-top">Provider</th>
                <th
                  class="min-w-70px align-top text-end"
                  style="padding-right: 0.5rem"
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in payments.items"
                :key="item.id"
                class="align_center"
                :class="{
                  pale_text:
                    item.type === 'PROMOTION' ||
                    item.type === 'PROMOTION_REFUND',
                }"
              >
                <td>{{ item.id }}</td>
                <td>{{ item.created_at }}</td>
                <td>
                  <span
                    v-if="item.provider === 'voucher'"
                    :title="item.voucher.title"
                  >
                    {{ item.provider }} {{ item.voucher.code }}
                  </span>
                  <span v-else-if="item.provider === 'storecredit'">
                    {{ item.provider }} {{ item.store_credit_id }}
                  </span>
                  <span v-else>
                    <a v-if="item.url !== null" :href="item.url" target="_blank"
                      >{{ item.provider }}
                    </a>
                    <span v-else>
                      {{ item.provider }}
                    </span>
                  </span>
                </td>
                <td class="text-end" style="padding-right: 0.5rem">
                  <div
                    class="text-end"
                    :class="{
                      'text-danger': item.amount_eur < 0,
                      'text-success': item.amount_eur >= 0,
                    }"
                    :title="
                      [
                        item.currency.iso_code,
                        $filters.currency(item.amount_currency),
                        `(${item.conversion_rate})`,
                      ].join(' ')
                    "
                  >
                    {{ $filters.currencyEUR(item.amount_eur) }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <div v-else class="text-center">
        <loading-indicator />
      </div>
      <div v-if="balance !== null" class="row">
        <div class="col">
          <table class="table table-bordered">
            <tr>
              <td>Current order balance:</td>
              <td class="text-end">
                <b>{{ $filters.currencyEUR(balance.current) }}</b>
              </td>
            </tr>
            <tr>
              <td>Refundable:</td>
              <td class="text-end">
                <b>{{ $filters.currencyEUR(balance.refundable) }}</b>
              </td>
            </tr>
            <tr>
              <td>Refundable by money:</td>
              <td class="text-end">
                <b>{{ $filters.currencyEUR(balance.refundable_by_money) }}</b>
              </td>
            </tr>
            <tr>
              <td>Pending refunds:</td>
              <td class="text-end">
                <b>{{ $filters.currencyEUR(balance.pending_refunds) }}</b>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div v-else class="text-center">
        <loading-indicator />
      </div>
    </template>
  </card-component>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive, toRefs } from "vue";
import CardComponent from "@/components/card/CardComponent.vue";
import LoadingIndicator from "@/components/spinners/LoadingIndicator.vue";
import RequestFailedError from "@/components/errors/RequestFailedError.vue";
import { OrderPaymentsResponse } from "@/modules/orders/interfaces/OrderPaymentsResponse.interface";
import { OrderBalance } from "@/modules/orders/interfaces/OrderBalance.interface";

interface ComponentData {
  loading: boolean;
}

export default defineComponent({
  name: "OrderPaymentsWidget",
  components: { LoadingIndicator, CardComponent },
  props: {
    id: {
      type: Number,
      required: true,
    },
    payments: {
      type: Object as PropType<OrderPaymentsResponse>,
      required: true,
    },
    balance: {
      type: Object as PropType<OrderBalance>,
      required: true,
    },
  },
});
</script>

<style scoped>
.pale_text {
  opacity: 0.4;
}
</style>
