
import { defineComponent, PropType, reactive, ref } from "vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { ElMessageBox } from "element-plus";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import { OrderMark } from "@/modules/common/interfaces/OrderMarksResponse.interface";

export default defineComponent({
  name: "OrderMarksModal",
  components: { FormSubmitButton, ModalComponent, Form, Field, ErrorMessage },
  props: {
    id: {
      type: Number,
      required: true,
    },
    marks: {
      type: Array as PropType<OrderMark[]>,
      required: true,
    },
  },
  emits: ["hide", "assigned"],
  setup(props, { emit }) {
    const validationSchema = Yup.object().shape({
      mark_id: Yup.number().required().label("Mark"),
    });

    const modal = ref<typeof ModalComponent | null>(null);

    const state = reactive({
      loading: false,
    });

    const sendRequest = (values) => {
      state.loading = true;
      ApiService.post(`order/${props.id}/marks`, values)
        .then(() => {
          emit("assigned");
          modal.value?.hide();
        })
        .catch(Notify.apiError)
        .finally(() => {
          state.loading = false;
        });
      return false;
    };

    const onSubmit = (values) => {
      ElMessageBox.confirm(
        "This will assign a Mark to order. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          sendRequest(values);
        })
        .catch(() => {
          // do nothing
        });
    };

    return {
      validationSchema,
      state,
      onSubmit,
      modal,
    };
  },
});
