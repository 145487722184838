<template>
  <modal-component
    id="order-add-extras-modal"
    ref="modal"
    size="medium"
    @hide="() => $emit('hide')"
  >
    <template #title>
      Adding an order extra to the order #{{ props.id }}
    </template>
    <template #default>
      <div class="row">
        <div class="col-8">
          <Multiselect
            v-if="state.serviceFeeTypes"
            id="idExtraType"
            v-model="state.idExtraType"
            :options="state.serviceFeeTypes"
            :searchable="true"
            name="idExtraType"
            placeholder="Select Fee Type"
            @change="pickExtraType"
          />
        </div>
        <div class="col-4">
          <input
            v-model="state.amount"
            type="text"
            class="form-control form-control-solid"
            placeholder="Amount"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <form @submit="addOrderExtra">
        <div class="row">
          <form-submit-button :loading="state.submitButtonLoading">
            Add an order extra
          </form-submit-button>
        </div>
      </form>
    </template>
  </modal-component>
</template>

<script lang="ts">
import { reactive, ref, onMounted, defineComponent } from "vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import ApiService from "@/core/services/ApiService";
import Multiselect from "@vueform/multiselect";

import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import { useForm } from "vee-validate";
import { ElMessage } from "element-plus";
import Notify from "@/modules/common/helpers/Notify";

interface ServiceFeeType {
  id: number;
  name: string;
  amount: number;
  value: number;
  label: string;
}

interface ComponentData {
  submitButtonLoading: boolean;
  serviceFeeTypes?: ServiceFeeType[];
  idExtraType?: number;
  amount?: number;
}

export default defineComponent({
  name: "OrderAdExtrasModal",
  components: {
    ModalComponent,
    Multiselect,
    FormSubmitButton,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  emits: ["added", "hide"],

  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
      serviceFeeTypes: undefined,
      idExtraType: undefined,
      amount: 0,
    });
    const modal = ref<typeof ModalComponent | null>(null);
    const { handleSubmit } = useForm();

    const loadData = () => {
      ApiService.get(`order/extras`)
        .then(({ data }) => {
          state.serviceFeeTypes = data.extras.map((item) => ({
            value: item.id,
            id: item.id,
            amount: item.price,
            name: item.name,
            label: item.name,
          }));
        })
        .catch((error) => Notify.apiError(error));
    };

    const pickExtraType = (extraId: number) => {
      state.amount = state.serviceFeeTypes?.find(
        (item) => item.id === extraId
      )?.amount;
    };

    const addOrderExtra = handleSubmit(() => {
      state.submitButtonLoading = true;
      ApiService.post(`order/${props.id}/extras`, {
        extra_id: state.idExtraType,
        amount: state.amount,
      })
        .then(({ data }) => {
          ElMessage.success("Item added.");
          modal.value?.hide();
          emit("added");
        })
        .catch((error) => Notify.apiError(error))
        .finally(() => {
          state.submitButtonLoading = false;
        });
    });

    onMounted(() => {
      loadData();
    });

    return {
      state,
      props,
      modal,
      pickExtraType,
      addOrderExtra,
    };
  },
});
</script>

<style scoped></style>
