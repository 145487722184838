<template>
  <a
    :href="$filters.appendTokenToUrl(windUrl + 'invoice/order/' + props.id)"
    target="_blank"
    class="btn btn-sm btn-secondary mx-2"
  >
    <i class="fas fa-file"></i>
    View Invoice
  </a>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "OrderViewInvoiceButton",
  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const windUrl = process.env.VUE_APP_BASIC_URL;

    return {
      props,
      windUrl,
    };
  },
});
</script>

<style scoped></style>
