<template>
  <modal-component
    id="order-reship-modal"
    ref="modal"
    size="medium"
    @hide="() => $emit('hide')"
  >
    <template #title> Return instructions</template>
    <template #default>
      <div class="row text-center">
        <h4>Are you sure to send return instructions to customers ?</h4>
      </div>
      <div class="row">
        <form-submit-button
          :loading="state.submitButtonLoading"
          @click="() => returnInstructions()"
        >
          Confirm
        </form-submit-button>
      </div>
    </template>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, reactive, ref } from "vue";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus";
import Notify from "@/modules/common/helpers/Notify";

interface ComponentData {
  submitButtonLoading: boolean;
  pickedItems: number[];
}

export default defineComponent({
  name: "OrderReturnInstructionsModal",

  components: {
    FormSubmitButton,
    ModalComponent,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  emits: ["done", "hide"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
      pickedItems: [],
    });
    const modal = ref<typeof ModalComponent | null>(null);

    const returnInstructions = () => {
      state.submitButtonLoading = true;
      ApiService.post(`order/${props.id}/return-instructions`, {
        groups: [state.pickedItems],
      })
        .then(() => {
          state.submitButtonLoading = false;
          ElMessage.success("Return instructions has been sent to customers.");
          modal.value?.hide();
          emit("done");
        })
        .catch((error) => {
          Notify.apiError(error);
        })
        .finally(() => {
          state.submitButtonLoading = false;
        });
    };

    return {
      state,
      props,
      returnInstructions,
      modal,
    };
  },
});
</script>

<style scoped></style>
