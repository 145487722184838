<template>
  <modal-component
    id="order-add-item-modal"
    ref="modal"
    :close-on-background-click="false"
    @hide="$emit('hide')"
  >
    <template #title> Adding item to the order</template>
    <template #default>
      <form @submit="addItem">
        <div class="row align-items-center d-flex">
          <h5>Pick an item</h5>
          <div class="col-6">
            <div class="row align-items-center d-flex">
              <div class="col-2">
                <img :src="state.imageUrl" width="80" />
              </div>

              <div class="col-7">
                <div class="row">
                  <div class="col">
                    <label class="form-label"
                      >Product
                      <Multiselect
                        id="pickedProduct"
                        v-model="state.pickedProduct"
                        :options="state.productsOptions"
                        :searchable="true"
                        :filterResults="false"
                        :loading="state.isLoadingProducts"
                        placeholder="Type ID or product name"
                        label="name"
                        @search-change="asyncFindProduct"
                        @change="changeProduct"
                      />
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="pickedProduct" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-3">
                <label class="form-label" style="width: 100%">
                  Variation
                  <Multiselect
                    v-model="state.pickedVariation"
                    :options="state.variationOptions"
                    placeholder="Select attribute"
                    label="name"
                    @change="changeVariation"
                  />
                </label>
                <br />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="pickedVariation" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="row align-items-center d-flex">
              <div class="col-3">
                <label for="quantity" class="form-label">
                  Quantity
                  <Field
                    id="quantity"
                    v-model="state.quantity"
                    type="number"
                    min="1"
                    max="999"
                    step="1"
                    class="form-control form-control-solid"
                    name="quantity"
                    placeholder="Quantity"
                    required
                    @keypress="($event) => integerFilter($event)"
                    @input="() => validateQuantity()"
                  />
                </label>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="quantity" />
                  </div>
                </div>
              </div>

              <div class="col-2">
                <label for="price" class="form-label">
                  Price €
                  <Field
                    id="price"
                    v-model="state.price"
                    type="text"
                    class="
                      form-control form-control-white
                      cursor_not_allowed
                      px-1
                    "
                    name="price"
                    disabled
                  />
                </label>
              </div>

              <div class="col-3">
                <label for="reductionPercent" class="form-label">
                  Reduction %
                  <input
                    id="reductionPercent"
                    v-model="state.reductionPercent"
                    type="number"
                    class="form-control form-control-solid"
                    name="reductionPercent"
                    placeholder="Percent"
                    @input="() => adjustFinalPrice()"
                  />
                </label>
              </div>

              <div class="col-3">
                <label for="finalPrice" class="form-label">
                  Final price €
                  <Field
                    id="finalPrice"
                    v-model="state.finalPrice"
                    type="number"
                    class="form-control form-control-solid"
                    name="finalPrice"
                    placeholder="Final price"
                    required
                    @input="() => adjustReductionPercent()"
                  />
                </label>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="finalPrice" />
                  </div>
                </div>
              </div>

              <div class="col-1 p-0">
                <button
                  type="button"
                  class="btn btn-sm btn-secondary"
                  title="Pick item"
                  @click="pickItem"
                >
                  <i class="fas fa-plus p-0"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="state.pickedProducts.length > 0" class="row">
          <div class="col-12">
            <table style="width: 100%">
              <thead>
                <tr>
                  <th colspan="2">Product</th>
                  <th>Product ID</th>
                  <th>Product Variation ID</th>
                  <th>Price</th>
                  <th>Discount</th>
                  <th>Final price</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in state.pickedProducts" :key="item.id">
                  <td>
                    <img :src="item.image_url" width="40" :alt="item.name" />
                  </td>
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    {{ item.product_id }}
                  </td>
                  <td>
                    {{ item.product_variation_id }}
                  </td>
                  <td>
                    {{ item.price }}
                  </td>
                  <td>{{ item.discount }}%</td>
                  <td>
                    {{ item.final_price }}
                  </td>
                  <td>
                    {{ item.quantity }}
                  </td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-sm"
                      style="padding: 0.25rem 0"
                      @click="() => deleteItem(item.id)"
                    >
                      <i
                        class="fas text-danger fa-times"
                        style="padding-right: 0"
                      ></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
      <form @submit="addItem">
        <div class="row mt-3">
          <form-submit-button
            :loading="state.submitButtonLoading"
            :disabled="state.disabledSendButton"
          >
            Add items
          </form-submit-button>
        </div>
      </form>
    </template>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from "vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import { ErrorMessage, Field, useForm } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import { ElMessage } from "element-plus";

interface Product {
  id: number;
  name: string;
  product_id: number;
  image_id: number;
  manufacturer: string;
  image_url: string;
  image_dialog_url: string;
}

interface PickedProduct {
  id: number;
  price: number;
  final_price: number;
  discount: number;
  product_variation_id: number;
  product_id: number;
  quantity: number;
  name: string;
  image_url: string;
}

interface Options {
  name: string;
  value: string;
}

interface Variation {
  id: number;
  variation: string;
  price: number;
}

interface ComponentData {
  pickedProducts?: PickedProduct[];
  pickedProduct?: Product | number | null;
  products: Product[] | null;
  pickedVariation: number | null;
  variations: Variation[] | null;
  productsOptions: Options[] | null;
  variationOptions: Options[] | null;
  isLoadingProducts: boolean;
  imageUrl?: string | null;
  submitButtonLoading: boolean;
  addressId: number | null;
  quantity: number;
  price: number;
  finalPrice: number;
  reductionPercent: number;
  disabledSendButton: boolean;
}

export default defineComponent({
  name: "OrderAddItemModal",

  components: {
    Multiselect,
    Field,
    ErrorMessage,
    FormSubmitButton,
    ModalComponent,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  emits: ["itemAdded", "hide"],

  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      pickedProducts: [],
      pickedProduct: null,
      pickedVariation: null,
      products: [],
      variations: [],
      productsOptions: [],
      variationOptions: [],
      isLoadingProducts: false,
      imageUrl: null,
      submitButtonLoading: false,
      addressId: null,
      quantity: 1,
      price: 0,
      finalPrice: 0,
      reductionPercent: 0,
      disabledSendButton: true,
    });
    const modal = ref<typeof ModalComponent | null>(null);
    const { setFieldValue, handleSubmit, setFieldError } = useForm();

    /** Filtering Start **/
    let changeTimer: number | undefined;
    let currentPickedItemId = 0;
    const maxQuantity = 999;

    const resetTimer = () => {
      if (changeTimer != null) {
        clearTimeout(changeTimer);
        changeTimer = undefined;
      }
    };

    // const runTimer = () => {
    //   resetTimer();
    //   changeTimer = setTimeout(() => {
    //     // asyncFindProduct()
    //   }, 2000);
    // };

    const asyncFindProduct = (query): void => {
      if (query.length !== 0) {
        state.products = [];
        state.productsOptions = [];
        state.pickedProduct = null;
        state.isLoadingProducts = true;

        resetTimer();

        changeTimer = setTimeout(() => {
          ApiService.get(`products/?search=` + query)
            .then(({ data }) => {
              state.products = data;
              state.pickedProduct = null;

              state.productsOptions = data.map((item) => ({
                name: item.name + " (#" + item.product_id + ")",
                value: item.product_id,
              }));
            })
            .catch((error) => Notify.apiError(error))
            .finally(() => {
              state.isLoadingProducts = false;
            });
        }, 500);
      }
    };

    const changeProduct = (productId: number): void => {
      state.imageUrl = state.products?.find(
        (item) => item.product_id === productId
      )?.image_url;

      state.price = 0;
      state.finalPrice = 0;
      state.pickedVariation = null;
      state.variations = [];
      state.variationOptions = [];
      if (productId != null) {
        getProductVariations(productId);
        setFieldError("pickedProduct", undefined);
      }
    };

    const deleteItem = (itemId: number): void => {
      if (state.pickedProducts != undefined) {
        let index = state.pickedProducts.findIndex(
          (item) => item.id === itemId
        );
        state.pickedProducts.splice(index, 1);

        if (state.pickedProducts.length === 0) {
          state.disabledSendButton = true;
        }
      }
    };

    const getProductVariations = (value): void => {
      if (value !== null) {
        ApiService.get(`products/` + value + `/variations`)
          .then(({ data }) => {
            state.variations = data;
            state.variationOptions = data.map((item) => ({
              name: item.variation,
              value: item.id,
            }));
          })
          .catch((error) => Notify.apiError(error));
      }
    };

    const changeVariation = (productVariationId: number): void => {
      state.price = 0;
      state.finalPrice = 0;
      if (productVariationId != null) getProductPrice(productVariationId);

      setFieldError("pickedVariation", undefined);
    };

    const getProductPrice = (productVariationId): void => {
      ApiService.get(
        `products/` + productVariationId + `/price/` + state.addressId
      )
        .then(({ data }) => {
          state.price = data.price;
          state.finalPrice = data.price;
          adjustFinalPrice();
        })
        .catch((error) => Notify.apiError(error));
    };

    const addItem = handleSubmit(() => {
      if (state.pickedProducts?.length != 0) {
        state.submitButtonLoading = true;

        ApiService.post(`order/` + props.id.toString() + "/items", {
          items: state.pickedProducts,
        })
          .then(() => {
            state.submitButtonLoading = false;
            ElMessage.success("Item added.");
            modal.value?.hide();
            emit("itemAdded");
          })
          .catch((error) => {
            Notify.apiError(error);
          })
          .finally(() => {
            state.submitButtonLoading = false;
          });
      } else {
        throwValidationError();
      }
    });

    const getCountry = (): void => {
      ApiService.get(`order/` + props.id.toString() + "/address")
        .then(({ data }) => {
          state.addressId = data.address.country_id;
        })
        .catch((error) => Notify.apiError(error));
    };

    const integerFilter = ($event): void => {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode === 46) {
        $event.preventDefault();
      }

      if (state.quantity >= maxQuantity) {
        $event.preventDefault();
      }
    };

    const validateQuantity = (): void => {
      if (state.quantity > maxQuantity) {
        state.quantity = maxQuantity;
      }
    };

    const adjustFinalPrice = (): void => {
      if (state.reductionPercent >= 100 || state.finalPrice <= 0) {
        throwValidationError("correct reduction percent");
      }
      state.finalPrice = +(
        state.price -
        state.price * (state.reductionPercent / 100)
      ).toFixed(2);
    };

    const adjustReductionPercent = (): void => {
      if (state.finalPrice > 0 && state.price != 0) {
        state.reductionPercent = +(
          100 -
          (state.finalPrice / state.price) * 100
        ).toFixed(2);
      }
    };

    const throwValidationError = (
      message = "product, variation, quantity and final price"
    ): void => {
      Notify.apiError({
        name: "notSelectedProductOrVariation",
        message: "Please select " + message,
      });
    };

    const validatingFields = (): void => {
      let errors: string[] = [];

      if (state.pickedProduct == null) {
        errors[errors.length] = "product";
        setFieldError("pickedProduct", "Pick product");
      } else {
        setFieldError("pickedProduct", undefined);
      }

      if (state.pickedVariation == null) {
        errors[errors.length] = "variation";
        setFieldError("pickedVariation", "Pick variation");
      } else {
        setFieldError("pickedVariation", undefined);
      }

      if (Number(state.quantity) <= 0 || isNaN(state.quantity)) {
        errors[errors.length] = "quantity";
        setFieldError("quantity", "Wrong quantity");
      } else {
        setFieldError("quantity", undefined);
      }

      if (Number(state.price) <= 0 || isNaN(state.price)) {
        errors[errors.length] = "price";
        setFieldError("price", "Wrong price");
      } else {
        setFieldError("price", undefined);
      }

      if (Number(state.finalPrice) <= 0 || isNaN(state.finalPrice)) {
        errors[errors.length] = "final price";
        setFieldError("finalPrice", "Wrong final price");
      } else {
        setFieldError("finalPrice", undefined);
      }

      throwValidationError(errors.join(", "));
    };

    const pickItem = (): void => {
      if (state.pickedProducts !== undefined && state.products != null) {
        if (
          state.pickedProduct != null &&
          state.pickedVariation != null &&
          Number(state.price) > 0 &&
          Number(state.finalPrice) > 0 &&
          Number(state.quantity) > 0
        ) {
          var item = state.products.find(
            (item) => item.product_id === state.pickedProduct
          );

          state.quantity = +Number(state.quantity).toFixed();
          state.quantity = state.quantity >= 99 ? 99 : state.quantity;

          state.finalPrice = +Number(state.finalPrice).toFixed(2);
          state.reductionPercent = isNaN(state.reductionPercent)
            ? 0
            : +Number(state.reductionPercent).toFixed(2);

          currentPickedItemId++;

          if (item != undefined) {
            state.pickedProducts[state.pickedProducts.length] = {
              id: currentPickedItemId,
              price: state.price,
              final_price: state.finalPrice,
              product_variation_id: state.pickedVariation,
              product_id: item.product_id,
              quantity: state.quantity,
              name: item.name,
              image_url: item.image_url,
              discount: state.reductionPercent,
            };
          }

          state.disabledSendButton = false;
        } else {
          state.disabledSendButton = true;
          validatingFields();
        }
      }
    };

    onMounted(getCountry);

    return {
      state,
      asyncFindProduct,
      getProductVariations,
      getProductPrice,
      changeProduct,
      changeVariation,
      getCountry,
      addItem,
      modal,
      pickItem,
      deleteItem,
      adjustFinalPrice,
      adjustReductionPercent,
      integerFilter,
      validateQuantity,
    };
  },
});
</script>

<style>
.multiselect-option {
  font-size: 13px;
  line-height: 1rem;
}

.multiselect-single-label {
  font-size: 13px;
  line-height: 1rem;
  padding-right: 27px;
}

.multiselect-clear {
  width: 28px;
}

.multiselect-input {
  font-size: 14px;
}

.multiselect-search input {
  padding-left: 0.7rem !important;
}

.multiselect-no-options {
  font-size: 14px;
}

.cursor_not_allowed {
  cursor: not-allowed;
}
</style>
