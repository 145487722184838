<template>
  <card-component :content-blocked="state.performingAction">
    <template #header>
      <span>Order Marks</span>
    </template>
    <template v-if="state.performingAction === false" #toolbar>
      <button
        v-if="$filters.checkUserPermission('order_marks')"
        class="btn btn-secondary btn-sm"
        @click="() => showModal()"
      >
        Assign
      </button>
    </template>
    <template #default>
      <div v-if="state.loading" class="text-center">
        <loading-indicator />
      </div>
      <request-failed-error
        v-if="state.error"
        :exception="state.error"
        @refresh="loadData"
      />
      <template v-if="state.response != null">
        <div class="my-2">
          <div
            v-if="state.response.marks.length === 0"
            class="text-center text-muted"
          >
            No marks
          </div>
          <span
            v-for="mark in state.response.marks"
            :key="mark.id"
            class="me-2"
          >
            <order-mark-badge
              :mark="mark"
              @delete="() => deleteMark(mark.id)"
            />
          </span>
        </div>
      </template>
      <order-marks-modal
        v-if="state.modalVisible"
        :id="id"
        :marks="state.response != null ? state.response.available : []"
        @assigned="() => loadData()"
        @hide="() => hideModal()"
      />
    </template>
  </card-component>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs, watch } from "vue";
import CardComponent from "@/components/card/CardComponent.vue";
import OrderMarkBadge from "@/modules/orders/widgets/OrderMarkBadge.vue";
import { OrderMarksResponse } from "@/modules/common/interfaces/OrderMarksResponse.interface";
import ApiService from "@/core/services/ApiService";
import { ElMessageBox } from "element-plus";
import { MessageBoxData } from "element-plus/es/components/message-box/src/message-box.type";
import Notify from "@/modules/common/helpers/Notify";
import OrderMarksModal from "@/modules/orders/widgets/OrderMarks/OrderMarksModal.vue";
import RequestFailedError from "@/components/errors/RequestFailedError.vue";
import LoadingIndicator from "@/components/spinners/LoadingIndicator.vue";

interface ComponentData {
  loading: boolean;
  response?: OrderMarksResponse | undefined;
  error?: unknown;
  performingAction: boolean;
  modalVisible: boolean;
}

export default defineComponent({
  name: "OrderMarksWidget",
  components: {
    LoadingIndicator,
    RequestFailedError,
    OrderMarksModal,
    OrderMarkBadge,
    CardComponent,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { id } = toRefs(props);

    const state = reactive<ComponentData>({
      loading: true,
      response: undefined,
      error: undefined,
      performingAction: false,
      modalVisible: false,
    });

    const showModal = () => {
      state.modalVisible = true;
    };

    const hideModal = () => {
      state.modalVisible = false;
    };

    const resetState = (): void => {
      state.loading = true;
      state.response = undefined;
      state.error = undefined;
    };

    const loadData = (): void => {
      resetState();
      ApiService.get(`order/${props.id}/marks`)
        .then(({ data }: { data: OrderMarksResponse }) => {
          state.response = data;
        })
        .catch((error) => {
          state.error = error;
        })
        .finally(() => {
          state.loading = false;
        });
    };

    const confirmAction = (): Promise<MessageBoxData> => {
      return ElMessageBox.confirm("Are you sure?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        type: "warning",
        center: true,
      });
    };

    const deleteMark = (mark_id: number): void => {
      confirmAction()
        .then(() => {
          state.performingAction = true;
          ApiService.post(`order/${props.id}/marks/delete`, {
            mark_id,
          })
            .then(() => {
              loadData();
            })
            .catch(Notify.apiError)
            .finally(() => {
              state.performingAction = false;
            });
        })
        .catch(() => {
          // do nothing
        });
    };

    onMounted(loadData);

    watch(id, loadData);

    return { state, showModal, hideModal, loadData, deleteMark };
  },
});
</script>

<style scoped></style>
