<template>
  <button
    type="button"
    class="btn btn-sm btn-secondary"
    @click="
      () => {
        state.isReturnInstructionsModal = true;
      }
    "
  >
    <i class="fas fa-file"></i>
    Return instructions
  </button>

  <OrderReturnInstructionsModal
    v-if="state.isReturnInstructionsModal === true"
    :id="id"
    @hide="
      () => {
        state.isReturnInstructionsModal = false;
      }
    "
    @done="() => done()"
  />
</template>

<script lang="ts">
import { defineComponent, PropType, reactive } from "vue";
import OrderReturnInstructionsModal from "@/modules/orders/widgets/OrderManagement/OrderReturnInstructionsModal.vue";

interface ComponentData {
  isReturnInstructionsModal: boolean;
}

export default defineComponent({
  name: "OrderReturnInstructionsButton",
  components: { OrderReturnInstructionsModal },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  emits: ["updated"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      isReturnInstructionsModal: false,
    });

    function done() {
      state.isReturnInstructionsModal = false;
      emit("updated");
    }

    return {
      state,
      props,
      done,
    };
  },
});
</script>

<style scoped></style>
