
import { defineComponent, onMounted, PropType, reactive } from "vue";
import * as OrderDetails from "../../interfaces/OrderDetails.interface";
import get from "lodash/get";
import AddressUpdateModal from "@/modules/addresses/widgets/AddressUpdateModal.vue";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import CustomerUpdateModal from "@/modules/customers/widgets/CustomerUpdateModal.vue";
import { Customer } from "@/modules/customers/interfaces/CustomerResponse.interface";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import OrderSetTrackingNumberButton from "@/modules/orders/widgets/OrderTracking/OrderSetTrackingNumberButton.vue";
import { TrackingHistory } from "@/modules/orders/interfaces/OrderTrackingHistory.interface";
import OrderSetTrackingNumberModal from "@/modules/orders/widgets/OrderTracking/OrderSetTrackingNumberModal.vue";

interface ComponentData {
  isEditAddress: boolean;
  isOrderCompleted: boolean;
  editableAddress: OrderDetails.Order | null;
  isEditCustomer: boolean;
  isSetTrackingNumberButton: boolean;
  customer: Customer | null;
}

export default defineComponent({
  name: "OrderInformationTable",
  components: {
    OrderSetTrackingNumberButton,
    AddressUpdateModal,
    CustomerUpdateModal,
    OrderSetTrackingNumberModal,
  },
  props: {
    order: {
      type: Object as PropType<OrderDetails.Order>,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    orderTracking: {
      type: Object as PropType<TrackingHistory[]>,
      required: true,
    },
  },

  emits: ["updated", "reloadOrder"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      isEditAddress: false,
      isOrderCompleted: false,
      editableAddress: null,
      isEditCustomer: false,
      isSetTrackingNumberButton: false,
      customer: null,
    });
    const store = useStore();

    const isNotEmpty = (field: string): boolean => {
      return get(props.order, field) != null;
    };
    const resolveField = (field: string): string => {
      return get(props.order, field, "");
    };

    const editAddress = (address) => {
      state.editableAddress = address;
      state.isEditAddress = true;
    };

    const getCustomer = () => {
      ApiService.get(`customer/` + props.order.customer.id.toString())
        .then(({ data }) => {
          state.customer = data.customer;
        })
        .catch((error) => Notify.apiError(error))
        .finally();
    };

    const isOrderCompleted = () => {
      ApiService.get(`order/` + props.id.toString() + `/is-completed`)
        .then(({ data }) => {
          state.isOrderCompleted = data.isOrderCompleted;
        })
        .catch((error) => {
          Notify.apiError(error);
        });
    };

    onMounted(() => {
      isOrderCompleted();
      getCustomer();
      store.dispatch(Actions.SET_ORDER_INFO_ACTION, props.order);
    });

    const layout = localStorage.layout;

    return {
      emit,
      state,
      props,
      isNotEmpty,
      resolveField,
      editAddress,
      getCustomer,
      layout,
    };
  },
});
