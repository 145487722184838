
import { defineComponent, reactive } from "vue";
import OrderAddExtrasModal from "@/modules/orders/widgets/OrderExtras/OrderAddExtrasModal.vue";

interface ComponentData {
  isAddOrderExtrasModal: boolean;
}

export default defineComponent({
  name: "OrderAddExtrasButton",
  components: { OrderAddExtrasModal },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  emits: ["updated"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      isAddOrderExtrasModal: false,
    });

    function done() {
      state.isAddOrderExtrasModal = false;
      emit("updated");
    }

    return {
      state,
      props,
      done,
    };
  },
});
