
import { reactive, ref, defineComponent } from "vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import ApiService from "@/core/services/ApiService";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import { ElMessage } from "element-plus";
import Notify from "@/modules/common/helpers/Notify";

interface ComponentData {
  submitButtonLoading: boolean;
}

export default defineComponent({
  name: "OrderDeleteExtrasModal",
  components: {
    ModalComponent,
    FormSubmitButton,
  },
  props: {
    orderExtrasId: {
      type: Number,
      required: true,
    },
    orderId: {
      type: Number,
      required: true,
    },
  },

  emits: ["deleted", "hide"],

  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
    });
    const modal = ref<typeof ModalComponent | null>(null);

    const deleteOrderExtra = () => {
      state.submitButtonLoading = true;
      ApiService.post(`order/${props.orderId}/extras/delete`, {
        id: props.orderExtrasId,
      })
        .then((data) => {
          state.submitButtonLoading = false;
          ElMessage.success("Order refund by voucher request sent.");
          modal.value?.hide();
          emit("deleted");
        })
        .catch((error) => Notify.apiError(error))
        .finally(() => {
          state.submitButtonLoading = false;
        });
    };

    return {
      state,
      props,
      modal,
      deleteOrderExtra,
    };
  },
});
