<template>
  <div class="p-2 shadow-sm mb-1">
    <div class="row">
      <div class="col-4">
        <span class="badge badge-secondary">
          {{ userName }}
        </span>
      </div>
      <div class="col-8 text-end">
        <span class="bi bi-clock-history"></span>&nbsp;{{ message.created_at }}
        <button
          v-if="$filters.checkUserPermission('delete_message')"
          type="button"
          class="btn btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#delete_address_modal"
          style="padding: 0.25rem 0.5rem; margin-top: -4px"
          @click="() => deleteOrderMessage()"
        >
          <i
            class="fas text-danger fa-times"
            style="padding-right: 0; font-size: 1.5rem"
          ></i>
        </button>
      </div>
    </div>
    <!-- eslint-disable -->
    <div class="py-2" v-html="message.content" />
    <!-- eslint-enable -->
    <div v-if="message.attachment != null" class="text-end">
      <button
        type="button"
        class="btn btn-secondary btn-sm"
        :disabled="state.downloading"
        @click="downloadFile"
      >
        {{ message.attachment_name }}&nbsp;<span class="bi bi-download" />
        <span
          v-if="state.downloading"
          class="spinner-border spinner-border-sm align-middle ms-2"
        />
      </button>
    </div>
  </div>
  <order-delete-messages-modal
    v-if="state.isDeleteOrderMessageModal"
    :message-id="message.id"
    @deleted="() => messageDeleted()"
  />
</template>

<script lang="ts">
import { computed, defineComponent, PropType, reactive } from "vue";
import { MessagesEntity } from "../../interfaces/OrderMessagesResponse.interface";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import OrderDeleteMessagesModal from "@/modules/orders/widgets/OrderMessages/OrderDeleteMessagesModal.vue";

interface ComponentData {
  isDeleteOrderMessageModal: boolean;
  downloading: boolean;
}

export default defineComponent({
  name: "OrderMessage",
  components: {
    OrderDeleteMessagesModal,
  },
  props: {
    message: {
      type: Object as PropType<MessagesEntity>,
      required: true,
    },
  },
  emits: ["deleted", "hide"],

  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      downloading: false,
      isDeleteOrderMessageModal: false,
    });

    const messageDeleted = () => {
      state.isDeleteOrderMessageModal = false;
      emit("deleted");
    };

    const deleteOrderMessage = () => {
      state.isDeleteOrderMessageModal = true;
    };

    const downloadFile = (): void => {
      state.downloading = true;
      ApiService.get(`order/messages/file/${props.message.id}`, {
        responseType: "blob",
      })
        .then(({ data }) => {
          if (props.message.attachment_name != null) {
            const fileURL = window.URL.createObjectURL(new Blob([data]));
            const fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", props.message.attachment_name);
            document.body.appendChild(fileLink);

            fileLink.click();
          }
        })
        .catch((error) => {
          Swal.fire({
            text: error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        })
        .finally(() => {
          state.downloading = false;
        });
    };

    const userName = computed((): string => {
      if (props.message.user != null) {
        return props.message.user.name;
      }
      return "Unknown #" + props.message.user_id;
    });

    return {
      state,
      downloadFile,
      userName,
      deleteOrderMessage,
      messageDeleted,
    };
  },
});
</script>

<style scoped></style>
