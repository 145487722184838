<template>
  <button
    type="button"
    class="btn btn-sm btn-secondary"
    @click="
      () => {
        state.isSplitModal = true;
      }
    "
  >
    Split order
  </button>

  <order-split-modal
    v-if="state.isSplitModal === true"
    :items="items"
    :split-threshold="splitThreshold"
    @hide="
      () => {
        state.isSplitModal = false;
      }
    "
    @order-splitted="() => orderSplitted()"
  />
</template>

<script lang="ts">
import { defineComponent, PropType, reactive } from "vue";
import OrderSplitModal from "@/modules/orders/widgets/OrderManagement/OrderSplitModal.vue";
import { OrderItem } from "@/modules/orders/interfaces/OrderItemsResponse.interface";

interface ComponentData {
  isSplitModal: boolean;
}

export default defineComponent({
  name: "OrderSplitButton",
  components: { OrderSplitModal },
  props: {
    items: {
      required: true,
      type: Object as PropType<OrderItem[]>,
    },
    splitThreshold: {
      required: false,
      type: Number,
      default: 0,
    },
  },

  emits: ["updated"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      isSplitModal: false,
    });

    const splitModal = () => {
      state.isSplitModal = true;
    };

    const orderSplitted = () => {
      state.isSplitModal = false;
      emit("updated");
    };

    return {
      state,
      props,
      splitModal,
      orderSplitted,
    };
  },
});
</script>

<style scoped></style>
