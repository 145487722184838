<template>
  <div class="table-responsive">
    <table class="table items_table">
      <tbody v-for="item in items" :key="item.id">
        <tr v-if="item.deleted_at === null" class="align_center">
          <td rowspan="3">
            <el-popover placement="right" :width="370" trigger="hover">
              <template #default>
                <div class="row">
                  <div class="col">
                    <div class="image_container">
                      <img
                        v-if="item.image_dialog_url != null"
                        :src="item.image_dialog_url"
                        :alt="item.title"
                        width="350"
                      />
                      <div class="image_caption">
                        {{ item.manufacturer_name }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #reference>
                <div class="image_container">
                  <img
                    v-if="item.image_url != null"
                    :src="item.image_url"
                    :alt="item.title"
                    :class="{
                      'packing-image': layout === 'packing',
                    }"
                  />
                  <div class="image_caption">{{ item.manufacturer_name }}</div>
                </div>
              </template>
            </el-popover>
          </td>
          <td colspan="5">
            <a :href="buildOversightUrl(item)" target="_blank">{{
              item.title
            }}</a>
          </td>
        </tr>
        <tr v-if="item.deleted_at === null">
          <td>
            <div class="inner_header">Size</div>
            {{ item.variation_title }}
            <span v-if="item.variation_title_attribute != 0">
              <br />
              {{ item.variation_title_attribute }}
            </span>
          </td>
          <td>
            <div class="inner_header">Reference</div>
            {{ item.reference }}
          </td>
          <td>
            <div class="inner_header">Price</div>
            {{ $filters.currencyEUR(item.final_price) }}
          </td>
        </tr>
        <tr v-if="item.deleted_at === null">
          <td>
            <div class="inner_header">Barcode</div>
            <template v-if="!hasWarehouseData(item)">
              <badge type="danger">MISSING</badge>
            </template>
            <template v-if="hasWarehouseData(item)">
              {{ getItemBarcode(item) }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { OrderItem } from "@/modules/orders/interfaces/OrderItemsResponse.interface";
import Badge from "@/components/Badge.vue";

export default defineComponent({
  name: "OrderItemsPackingTable",
  components: {
    Badge,
  },
  props: {
    items: {
      required: true,
      type: Object as PropType<OrderItem[]>,
    },
    id: {
      type: Number,
      required: true,
    },
  },

  setup() {
    const buildOversightUrl = (item: OrderItem): string => {
      return `https://oversight.bambiniservices.com/product/${item.product_id}`;
    };

    const hasWarehouseData = (item: OrderItem): boolean => {
      return item.warehouse != null;
    };

    const getItemLocation = (item: OrderItem) => {
      if (item.warehouse != null) {
        return item.warehouse.location;
      }
      return "";
    };

    const getItemBarcode = (item: OrderItem) => {
      if (item.warehouse != null) {
        return item.warehouse.barcode;
      }
      return "";
    };

    const layout = localStorage.layout;

    return {
      buildOversightUrl,
      hasWarehouseData,
      getItemLocation,
      getItemBarcode,
      layout,
    };
  },
});
</script>

<style scoped>
.image_container {
  position: relative;
  text-align: center;
  color: white;
}
.image_container img {
  /*position: absolute;*/
}
.el-popover .image_caption {
  font-size: 1.5rem;
}
.image_caption {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px 10px;
  border-radius: 6px;
  background-color: #f5f5f5e0;
  color: black;
}
.items_table th {
  font-size: 1.2rem;
}
.items_table td {
  font-size: 1.3rem;
}

.align_center {
  vertical-align: middle;
}

.items_table td {
  padding: 5px 6px;
}
.packing-image {
  width: 250px;
}

.inner_header {
  color: #a2a2a2;
  font-size: 1rem;
}
</style>
