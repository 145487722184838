<template>
  <div v-if="props.orderTracking.length > 0">
    <div v-for="item in props.orderTracking" :key="item.id">
      <div v-if="item.type === 'return'">
        <hr />
      </div>
      <a :href="item.url" target="_blank">
        {{ item.code }}
        (type {{ item.type }})
      </a>
    </div>
  </div>
  <div v-else>
    <button
      type="button"
      class="btn btn-sm btn-secondary"
      @click="
        () => {
          state.isSetTrackingNumberButton = true;
        }
      "
    >
      Set tracking number
    </button>
  </div>

  <OrderSetTrackingNumberModal
    v-if="state.isSetTrackingNumberButton === true"
    :id="id"
    :order-tracking="props.orderTracking"
    @hide="
      () => {
        state.isSetTrackingNumberButton = false;
      }
    "
    @updated="() => done()"
  />
</template>

<script lang="ts">
import { defineComponent, PropType, reactive } from "vue";
import OrderSetTrackingNumberModal from "@/modules/orders/widgets/OrderTracking/OrderSetTrackingNumberModal.vue";
import { TrackingHistory } from "@/modules/orders/interfaces/OrderTrackingHistory.interface";

interface ComponentData {
  isSetTrackingNumberButton: boolean;
}

export default defineComponent({
  name: "OrderSetTrackingNumberButton",
  components: { OrderSetTrackingNumberModal },
  props: {
    id: {
      type: Number,
      required: true,
    },
    orderTracking: {
      type: Object as PropType<TrackingHistory[]>,
      required: true,
    },
  },

  emits: ["updated"],

  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      isSetTrackingNumberButton: false,
    });

    function done() {
      state.isSetTrackingNumberButton = false;
      emit("updated");
    }

    return {
      state,
      props,
      done,
    };
  },
});
</script>

<style scoped>
hr {
  margin: 0.25rem;
}
</style>
