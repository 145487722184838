
import { defineComponent, PropType } from "vue";
import { OrderItem } from "@/modules/orders/interfaces/OrderItemsResponse.interface";
import Badge from "@/components/Badge.vue";

export default defineComponent({
  name: "OrderItemsPackingTable",
  components: {
    Badge,
  },
  props: {
    items: {
      required: true,
      type: Object as PropType<OrderItem[]>,
    },
    id: {
      type: Number,
      required: true,
    },
  },

  setup() {
    const buildOversightUrl = (item: OrderItem): string => {
      return `https://oversight.bambiniservices.com/product/${item.product_id}`;
    };

    const hasWarehouseData = (item: OrderItem): boolean => {
      return item.warehouse != null;
    };

    const getItemLocation = (item: OrderItem) => {
      if (item.warehouse != null) {
        return item.warehouse.location;
      }
      return "";
    };

    const getItemBarcode = (item: OrderItem) => {
      if (item.warehouse != null) {
        return item.warehouse.barcode;
      }
      return "";
    };

    const layout = localStorage.layout;

    return {
      buildOversightUrl,
      hasWarehouseData,
      getItemLocation,
      getItemBarcode,
      layout,
    };
  },
});
