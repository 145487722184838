<template>
  <Form class="form w-100" @submit="onSubmit">
    <!--begin::Input group-->
    <div class="fv-row">
      <!--begin::Input-->
      <Field v-slot="{ field }" name="content">
        <textarea
          class="form-control form-control-solid form-control-sm"
          v-bind="field"
          placeholder="Your comment..."
        />
      </Field>
      <!--end::Input-->
      <div class="fv-plugins-message-container">
        <div class="fv-help-block">
          <ErrorMessage name="content" />
        </div>
      </div>
    </div>
    <!--end::Input group-->
    <div class="row">
      <div class="col">
        <div class="py-2">
          <Field name="attachmentFile" type="file" accept="application/pdf" />
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="attachmentFile" />
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <form-submit-button :loading="state.loading" class="w-100">
          <slot>Send</slot>
        </form-submit-button>
      </div>
    </div>
  </Form>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";

export default defineComponent({
  name: "OrderMessagesForm",
  components: {
    FormSubmitButton,
    Field,
    Form,
    ErrorMessage,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  emits: ["updated"],
  setup(props, { emit }) {
    const state = reactive({
      loading: false,
    });

    const validationSchema = Yup.object().shape({
      content: Yup.string().required().label("Comment"),
    });

    const onSubmit = (values, { resetForm, setFieldError }) => {
      state.loading = true;

      const formData = new FormData();
      if (values.content) formData.append("content", values.content);
      if (values.attachmentFile != null) {
        formData.append("attachmentFile", values.attachmentFile[0]);
      }

      ApiService.post(`order/` + props.id + `/messages`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(() => {
          emit("updated");
          resetForm();
        })
        .catch((error) => Notify.apiError(error, setFieldError))
        .finally(() => {
          state.loading = false;
        });
      return false;
    };

    return {
      state,
      props,
      validationSchema,
      onSubmit,
    };
  },
});
</script>

<style scoped>
textarea {
  height: 4rem;
}
</style>
