<template>
  <modal-component
    id="order-generate-label-modal"
    ref="modal"
    size="large"
    :close-on-background-click="false"
    @hide="() => $emit('hide')"
  >
    <template #title>Generate tracking</template>
    <template #default>
      <div class="row mb-4">
        <div
          v-if="state.parcelLabels != null"
          class="col-7"
          style="border-right: 1px solid #eee"
        >
          <div class="row mb-2 text-center">
            <h5 class="col">Bags:</h5>
          </div>
          <div class="row mb-2">
            <div
              v-for="parcel in state.bagLabels"
              :key="parcel.id"
              :class="{ 'col-4 text-center': parcel.type === 'Bag' }"
            >
              <button
                v-if="parcel.type === 'Bag'"
                type="button"
                class="btn btn_sizes btn-primary"
                @click="
                  () =>
                    addParcelByBtn(parcel.id, parcel.type + ' ' + parcel.name)
                "
              >
                {{ parcel.name }}
              </button>
            </div>
          </div>
          <div class="row mb-2 text-center">
            <h5 class="col">Boxes:</h5>
          </div>
          <div class="row mb-2">
            <div
              v-for="parcel in state.boxLabels"
              :key="parcel.id"
              :class="{
                'col-4 text-center':
                  parcel.type === 'Box' && parcel.name !== '9',
                'col-8': parcel.name === '9',
              }"
            >
              <button
                v-if="parcel.type === 'Box'"
                type="button"
                class="btn btn_sizes btn-primary"
                @click="
                  () =>
                    addParcelByBtn(parcel.id, parcel.type + ' ' + parcel.name)
                "
              >
                {{ parcel.name }}
              </button>
              <div v-if="parcel.name === '9'"></div>
            </div>
          </div>
        </div>
        <div class="col-5">
          <div class="row mb-2 text-center">
            <div class="col text-muted">
              <h4>Selected:</h4>
            </div>
          </div>
          <div
            v-for="parcel in state.pickedParcels"
            :key="parcel.id"
            class="row mb-1"
          >
            <div class="col text-center">
              <span class="badge bg-light text-dark" style="font-size: 1.35rem">
                {{ parcel.name }}
                <button
                  type="button"
                  class="btn btn-sm mx-2"
                  style="padding: 0.25rem 0"
                  @click="() => deleteParcel(parcel.id)"
                >
                  <i
                    class="fas text-danger fa-times"
                    style="padding-right: 0"
                  ></i>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <form-submit-button
          :loading="state.submitButtonLoading"
          @click="() => generateLabel()"
        >
          Generate tracking
        </form-submit-button>
      </div>
    </template>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import ModalComponent from "@/components/modals/ModalComponent.vue";
import { ElMessage } from "element-plus";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";

interface PickedParcel {
  id: number;
  value: string | number;
  name: string;
}

interface ParcelLabel {
  id: number;
  name: string;
  type: string;
}

interface ComponentData {
  submitButtonLoading: boolean;
  isSingleParcel: boolean;
  pickedParcels: PickedParcel[];
  parcelLabels?: ParcelLabel[];
  bagLabels?: ParcelLabel[];
  boxLabels?: ParcelLabel[];
  parcelId: number;
}

export default defineComponent({
  name: "OrderGenerateLabelModal",
  components: {
    FormSubmitButton,
    ModalComponent,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  emits: ["updated", "hide"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      submitButtonLoading: false,
      isSingleParcel: true,
      pickedParcels: [],
      parcelLabels: [],
      bagLabels: [],
      boxLabels: [],
      parcelId: 0,
    });

    const modal = ref<typeof ModalComponent | null>(null);

    const getParcelLabels = () => {
      ApiService.get(`shipping-label`)
        .then(({ data }) => {
          state.parcelLabels = data.parcelTypes;

          state.bagLabels = data.parcelTypes?.filter((item) => {
            if (item.type === "Bag") {
              return item;
            }
          });
          state.boxLabels = data.parcelTypes?.filter((item) => {
            if (item.type === "Box") {
              return item;
            }
          });
        })
        .catch((error) => Notify.apiError(error))
        .finally(() => {
          state.submitButtonLoading = false;
        });
    };

    getParcelLabels();

    const generateLabel = () => {
      let requestData = state.pickedParcels.map((item) => {
        return item.value;
      });

      state.submitButtonLoading = true;

      ApiService.post(`shipping-label/${props.id}`, {
        parcels_type: requestData,
      })
        .then(({ data }) => {
          if (data.type === "success") {
            ElMessage.success("Tracking generated");
            modal.value?.hide();
            emit("updated");
          }
        })
        .catch((error) => Notify.apiError(error))
        .finally(() => {
          state.submitButtonLoading = false;
        });
    };

    const deleteParcel = (id: number) => {
      let index = state.pickedParcels.findIndex((item) => item.id === id);
      state.pickedParcels.splice(index, 1);
    };

    const addParcelByBtn = (id: number, name: string) => {
      state.parcelId++;
      state.pickedParcels.push({
        id: state.parcelId,
        value: id,
        name: name,
      });
    };

    return {
      props,
      state,
      deleteParcel,
      generateLabel,
      addParcelByBtn,
      modal,
      getParcelLabels,
    };
  },
});
</script>

<style scoped>
.btn_sizes {
  font-size: 2rem;
  height: 6rem;
  width: 6rem;
  margin: 0.2rem 2rem;
}
</style>
