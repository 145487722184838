<template>
  <div v-if="orderFamily.parentOrders" id="parents_buttons">
    <button
      v-for="order in orderFamily.parentOrders"
      :key="order"
      type="button"
      class="btn btn-sm btn-secondary m-2"
      @click="goToFamilyOrder(order, $event)"
    >
      To parent #{{ order }}
    </button>
  </div>
  <div v-if="orderFamily.childrenOrders" id="children_buttons">
    <a
      v-for="order in orderFamily.childrenOrders"
      :key="order"
      type="button"
      class="btn btn-sm btn-secondary m-2"
      @click="goToFamilyOrder(order, $event)"
    >
      To child #{{ order }}
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { RouteLocation, useRouter } from "vue-router";
import { OrderFamily } from "@/modules/orders/interfaces/OrderItemsResponse.interface";

export default defineComponent({
  name: "OrderFamilyOrders",

  props: {
    orderFamily: {
      type: Object as PropType<OrderFamily[]>,
      required: true,
    },
  },

  emits: ["updated"],
  setup(props) {
    const router = useRouter();
    const goToFamilyOrder = (order: number, event: MouseEvent) => {
      const orderRoute: RouteLocation = router.resolve({
        name: "orders-view",
        params: { id: order },
      });
      if (event.ctrlKey || event.metaKey) {
        window.open(orderRoute.fullPath, "_blank");
        return;
      }
      router.push(orderRoute);
    };

    return {
      props,
      goToFamilyOrder,
    };
  },
});
</script>

<style scoped></style>
