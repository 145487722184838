<template>
  <div class="table-responsive">
    <div class="row mt-2">
      <div class="col-2">
        <select
          id="items_bulk_actions"
          v-model="state.bulkAction"
          class="form-select form-select-sm"
          :disabled="state.isBulkActionsEnabled === false"
        >
          <option value="default" selected>Bulk action</option>
          <option
            v-if="$filters.checkUserPermission('delete_item')"
            value="delete"
          >
            Delete items
          </option>
          <option
            v-if="$filters.checkUserPermission('reship_item')"
            value="reship"
          >
            Reship items
          </option>
        </select>
      </div>
      <div class="col-1">
        <button
          :disabled="
            state.bulkAction === 'default' ||
            state.isBulkActionsEnabled === false
          "
          type="button"
          class="btn btn-sm btn-secondary"
          @click="() => applyBulkAction()"
        >
          Apply
        </button>
      </div>

      <div class="col-1">
        <order-split-button
          v-if="$filters.checkUserPermission('split_order')"
          :items="state.itemsWithoutDeleted"
          :split-threshold="state.splitThreshold"
          @updated="() => itemsUpdated()"
        />
      </div>

      <div class="col-2">
        <order-reship-lost-parcel-button
          v-if="$filters.checkUserPermission('reship_lost_parcel')"
          :items="state.itemsWithoutDeleted"
          @updated="() => itemsUpdated()"
        />
      </div>
    </div>
    <table class="table items_table">
      <thead>
        <tr class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
          <th></th>
          <th>
            <div class="form-check form-check-custom form-check-sm">
              <input
                v-model="state.isCheckAll"
                class="form-check-input"
                type="checkbox"
                @click="() => checkAll()"
              />
            </div>
          </th>
          <th></th>
          <th class="min-w-150px align-top">Product</th>
          <th class="min-w-100px align-top">Attribute</th>
          <th class="min-w-100px align-top">Reference</th>
          <th class="min-w-100px align-top">Location</th>
          <th v-if="layout === 'basic'" class="min-w-100px align-top">Price</th>
          <th v-if="layout === 'basic'" class="min-w-100px align-top">
            Reduction percent
          </th>
          <th class="min-w-100px align-top">Final Price</th>
          <th class="min-w-100px align-top">In stock</th>
          <th class="min-w-100px align-top">Action</th>
          <th class="min-w-100px align-top">Barcode</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in items"
          :key="item.id"
          class="align_center"
          :style="[item.deleted_at != null ? { background: '#ffdede' } : {}]"
        >
          <td>{{ ++index }}</td>
          <td>
            <div class="form-check form-check-custom form-check-sm">
              <input
                v-if="item.deleted_at == null"
                v-model="state.selected"
                class="form-check-input"
                type="checkbox"
                :value="item.id"
                @change="() => updateCheckAll()"
              />
            </div>
          </td>
          <td>
            <el-popover placement="right" :width="370" trigger="hover">
              <template #default>
                <div class="row">
                  <div class="col">
                    <img
                      v-if="item.image_dialog_url != null"
                      :src="item.image_dialog_url"
                      :alt="item.title"
                      width="350"
                    />
                  </div>
                </div>
              </template>
              <template #reference>
                <img
                  v-if="item.image_url != null"
                  :src="item.image_url"
                  :alt="item.title"
                  width="60"
                  :class="{
                    'tracking-image': layout === 'tracking',
                  }"
                />
              </template>
            </el-popover>
          </td>
          <td>
            <a :href="buildOversightUrl(item)" target="_blank">{{
              item.title
            }}</a>
	        <p>P# {{ item.product_id }} V# {{ item.product_variation_id }}</p>
          </td>
          <td>
            {{ item.variation_title }}<br />
            {{ item.variation_title_attribute }}
          </td>
          <td>{{ item.reference }}</td>
          <td>
            <template v-if="!hasWarehouseData(item)">
              <badge type="danger">MISSING</badge>
            </template>
            <template v-if="hasWarehouseData(item)"
              >{{ getItemLocation(item) }}
            </template>
          </td>
          <td v-if="layout === 'basic'">
            {{ $filters.currencyEUR(item.price) }}
          </td>
          <td v-if="layout === 'basic'">{{ item.reduction_percent }}%</td>
          <td>{{ $filters.currencyEUR(item.final_price) }}</td>
          <td>
            <strong
              :class="{
                'text-success': item.quantity > 0,
                'text-danger': item.quantity <= 0,
              }"
            >
              {{ item.quantity }}
            </strong>
          </td>
          <td>
            <span v-if="item.deleted_at != null" class="badge badge-danger"
              >DELETED</span
            >
            <span v-else>
              <button
                v-if="$filters.checkUserPermission('delete_item')"
                type="button"
                class="btn btn-sm btn-secondary"
                style="margin-right: 0.5rem"
                @click="() => deleteItems(item.id)"
              >
                <i class="fas fa-trash"></i>
                Delete
              </button>
            </span>
          </td>
          <td>
            <template v-if="!hasWarehouseData(item)">
              <badge type="danger">MISSING</badge>
            </template>
            <template v-if="hasWarehouseData(item)"
              >{{ getItemBarcode(item) }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <OrderItemsDeleteModal
    v-if="state.isDeleteModal === true"
    :id="id"
    :items="state.deletingItems"
    @hide="() => hideModal()"
    @item-deleted="() => itemsUpdated()"
  />

  <OrderReshipWrongItemsModal
    v-if="state.isReshipWrongItemsModal === true"
    :items="state.selected"
    @hide="() => hideModal()"
    @done="() => itemsUpdated()"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, reactive } from "vue";
import { OrderItem } from "@/modules/orders/interfaces/OrderItemsResponse.interface";
import Badge from "@/components/Badge.vue";
import OrderItemsDeleteModal from "@/modules/orders/widgets/OrderItems/OrderItemsDeleteModal.vue";
import OrderReshipWrongItemsModal from "@/modules/orders/widgets/OrderManagement/OrderReshipWrongItemsModal.vue";
import OrderSplitButton from "@/modules/orders/widgets/OrderManagement/OrderSplitButton.vue";
import OrderReshipLostParcelButton from "@/modules/orders/widgets/OrderManagement/OrderReshipLostParcelButton.vue";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";

interface ComponentData {
  selected: number[];
  splitThreshold: number | null;
  isCheckAll: boolean;
  bulkAction: string | null;
  pickedItems: OrderItem[] | null;
  deletingItems: OrderItem[] | null;
  isDeleteModal: boolean;
  isReshipWrongItemsModal: boolean;
  isReshipLostParcelModal: boolean;
  itemsWithoutDeleted: OrderItem[] | [];
  isBulkActionsEnabled: boolean;
}

export default defineComponent({
  name: "OrderItemsTable",
  components: {
    OrderReshipWrongItemsModal,
    OrderItemsDeleteModal,
    Badge,
    OrderReshipLostParcelButton,
    OrderSplitButton,
  },
  props: {
    items: {
      required: true,
      type: Object as PropType<OrderItem[]>,
    },
    id: {
      type: Number,
      required: true,
    },
  },

  emits: ["itemsUpdated"],
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      selected: [],
      splitThreshold: null,
      isCheckAll: false,
      bulkAction: "default",
      pickedItems: [],
      deletingItems: [],
      isDeleteModal: false,
      isReshipWrongItemsModal: false,
      isReshipLostParcelModal: false,
      itemsWithoutDeleted: [],
      isBulkActionsEnabled: false,
    });

    const buildOversightUrl = (item: OrderItem): string => {
      return `https://oversight.bambiniservices.com/product/${item.product_id}`;
    };

    const hasWarehouseData = (item: OrderItem): boolean => {
      return item.warehouse != null;
    };

    const getItemLocation = (item: OrderItem) => {
      if (item.warehouse != null) {
        return item.warehouse.location;
      }
      return "";
    };

    const getItemBarcode = (item: OrderItem) => {
      if (item.warehouse != null) {
        return item.warehouse.barcode;
      }
      return "";
    };

    const applyBulkAction = () => {
      if (state.bulkAction === "delete") deleteItems(state.selected);
      if (state.bulkAction === "reship") reshipItems();
      return false;
    };

    const hideModal = (): void => {
      state.isDeleteModal = false;
      state.isReshipWrongItemsModal = false;
      state.deletingItems = [];
    };

    const deleteItems = (picked): void => {
      state.isDeleteModal = true;
      state.deletingItems = [];

      for (const item of state.itemsWithoutDeleted) {
        if (typeof picked == "number" && item.id == picked) {
          state.deletingItems[state.deletingItems?.length] = item;
        }

        if (typeof picked == "object" && picked.includes(item.id)) {
          state.deletingItems[state.deletingItems?.length] = item;
        }
      }
    };

    const reshipItems = (): void => {
      state.isReshipWrongItemsModal = true;
    };

    const checkAll = (): void => {
      state.isCheckAll = !state.isCheckAll;
      state.isBulkActionsEnabled = state.isCheckAll;
      state.selected = [];
      if (state.isCheckAll) {
        for (const item of state.itemsWithoutDeleted) {
          state.selected.push(item.id);
        }
      }
    };

    const getCountryThreshold = () => {
      ApiService.post(`order/${props.id}/country/threshold`)
        .then(({ data }) => {
          state.splitThreshold = data["threshold"].split_threshold;
        })
        .catch((error) => {
          Notify.apiError(error);
        });
    };

    const itemsUpdated = () => {
      emit("itemsUpdated");
    };

    const updateCheckAll = () => {
      state.isBulkActionsEnabled = state.selected.length > 0;
      state.isCheckAll =
        state.selected.length == state.itemsWithoutDeleted.length;
    };

    onMounted(() => {
      getCountryThreshold();
      if (props.items != undefined) {
        state.itemsWithoutDeleted = [];
        for (let item of props.items) {
          if (item.deleted_at == null) {
            state.itemsWithoutDeleted[state.itemsWithoutDeleted.length] = item;
          }
        }
      }
    });

    const layout = localStorage.layout;

    return {
      state,
      buildOversightUrl,
      hasWarehouseData,
      getItemLocation,
      getItemBarcode,
      checkAll,
      updateCheckAll,
      applyBulkAction,
      deleteItems,
      reshipItems,
      itemsUpdated,
      hideModal,
      layout,
    };
  },
});
</script>

<style scoped>
.align_center {
  vertical-align: middle;
}

.items_table td {
  padding: 5px 6px;
}

.tracking-image {
  width: 90px;
}
</style>
