
import { defineComponent, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";

export default defineComponent({
  name: "OrderMessagesForm",
  components: {
    FormSubmitButton,
    Field,
    Form,
    ErrorMessage,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  emits: ["updated"],
  setup(props, { emit }) {
    const state = reactive({
      loading: false,
    });

    const validationSchema = Yup.object().shape({
      content: Yup.string().required().label("Comment"),
    });

    const onSubmit = (values, { resetForm, setFieldError }) => {
      state.loading = true;

      const formData = new FormData();
      if (values.content) formData.append("content", values.content);
      if (values.attachmentFile != null) {
        formData.append("attachmentFile", values.attachmentFile[0]);
      }

      ApiService.post(`order/` + props.id + `/messages`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(() => {
          emit("updated");
          resetForm();
        })
        .catch((error) => Notify.apiError(error, setFieldError))
        .finally(() => {
          state.loading = false;
        });
      return false;
    };

    return {
      state,
      props,
      validationSchema,
      onSubmit,
    };
  },
});
